<div class="rb-breadcrumb-container">
      <ul class="rb-breadcrumb rb-breadcrumb-elevated" [class.rb-has-subtitle]="showStepCount"
            [class.rb-breadcrumb-no-grow]="shrinkView">
            <li *ngIf="showhome" [class.rb-breadcrumb-icon-item]="true"
                  [ngClass]="{'rb-p-t-5 rb-p-b-5' : showStepCount }" class="rb-breadcrumb-item" (click)="onSelect(1)">
                  <a href="javascript:void(0);" [ngClass]="showStepCount? 'uk-text-left rb-p-10':'uk-text-center'"
                        [ngClass]="showStepCount? 'a-opacity' : ''">
                        <fa-icon class="rb-breadcrumb-icon" [icon]="icons['home']">
                        </fa-icon>
                  </a>
            </li>
            <li [class.active]="currentStep == i+1" [ngClass]="{'success':i+1 < currentStep}"
                  *ngFor="let obj of _steps; let i = index" [ngClass]="{'rb-p-t-5 rb-p-b-5' : showStepCount }"
                  class="rb-breadcrumb-item" (click)="onSelect(i+1)">
                  <a href="javascript:void(0);" [ngClass]="showStepCount? 'uk-text-left rb-p-10':'uk-text-center'"
                        [ngClass]="showStepCount? 'a-opacity' : ''">
                        <div class="uk-flex uk-flex-middle">
                              <div *ngIf="showStepCount" [ngClass]="{
                                    'number-status-current': currentStep == i+1,
                                    'number-status-completed': currentStep > i+1,
                                    'number-status-pending': currentStep < i+1
                              }" class="rb-breadcrumb-icon">{{i + 1}}</div>
                              <div class="rb-subtitle rb-m-l-15">
                                    <div *ngIf="showStepCount" class="rb-font-size-12" [ngClass]="{
                                    'stepper-status-current': currentStep == i+1,
                                    'stepper-status-completed': currentStep > i+1,
                                    'stepper-status-pending': currentStep < i+1
                              }">{{obj.subtitle}}</div>
                                    <div class="rb-font-size-15 rb-title" [ngClass]="{
                                    'stepper-status-pending': currentStep < i+1
                              }">
                                          {{obj.title}}
                                    </div>
                              </div>
                        </div>
                  </a>
            </li>
      </ul>
</div>