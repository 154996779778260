<p class="rb-font-size-12 uk-text-muted">Insurance is offered by D2C INSURANCE BROKING PVT. LTD
    (CIN
    U66030DL2013PTC249265), Principal
    Place of Business: 1st
    Floor, Plot no 94, Sector- 32, Gurugram -122001, Haryana; Registered Office: Second Floor,
    C-67, DDA Shed, Okhla
    Phase-1, Delhi-110020, IRDAI Broking License Code No. DB 571/14, Certificate No. 505,
    License category- Direct Broker
    (Life & General), valid till 26/11/2020. Insurance is the subject matter of the
    solicitation. Product information is
    solely based on the information received from the insurers. For more details on risk
    factors, associated terms and
    conditions and exclusions, please read the sales brochure carefully of respective insurer
    before concluding a sale.</p>
