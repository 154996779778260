import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteleStripComponent } from './components/clientele-strip/clientele-strip.component';
import { DownloadApplicationComponent } from './components/download-application/download-application.component';
import { FaqComponent } from './components/faq/faq.component';
import { FeaturesComponent } from './components/features/features.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProcessStepperComponent } from './components/process-stepper/process-stepper.component';
import { TestimonialSliderComponent } from './components/testimonial-slider/testimonial-slider.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RbDisclaimerComponent } from './components/rb-disclaimer/rb-disclaimer.component';
import { MasterPageComponent } from './pages/master-page/master-page.component';
import { PhotoUploaderComponent } from './components/photo-uploader/photo-uploader.component';
import { JourneyIndicatorComponent } from './components/journey-indicator/journey-indicator.component';
import { AllownumbersonlyDirective } from './directives/allownumbersonly.directive';
import { TemplateIdDirective } from './directives/template-id.directive';
import { RegexDirective } from './directives/regex.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalNavbarComponent } from './components/portal-navbar/navbar.component';
import { CommaSepratorPipe } from './utility/comma-separator.pipe';
import { AppDownloadAlertComponent } from './components/app-download-alert/app-download-alert.component';


@NgModule({
  declarations: [
    NavbarComponent,
    ClienteleStripComponent,
    ProcessStepperComponent,
    TestimonialSliderComponent,
    DownloadApplicationComponent,
    FaqComponent,
    FeaturesComponent,
    FooterComponent,
    RbDisclaimerComponent,
    MasterPageComponent,
    ProcessStepperComponent,
    PhotoUploaderComponent,
    JourneyIndicatorComponent,
    TemplateIdDirective,
    AllownumbersonlyDirective,
    RegexDirective,
    PortalNavbarComponent,
    CommaSepratorPipe,
    AppDownloadAlertComponent,
    AllownumbersonlyDirective,
    RegexDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
  ],
  exports: [
    FontAwesomeModule,
    NavbarComponent,
    ClienteleStripComponent,
    ProcessStepperComponent,
    TestimonialSliderComponent,
    DownloadApplicationComponent,
    FaqComponent,
    FeaturesComponent,
    FooterComponent,
    RbDisclaimerComponent,
    MasterPageComponent,
    ProcessStepperComponent,
    PhotoUploaderComponent,
    JourneyIndicatorComponent,
    TemplateIdDirective,
    AllownumbersonlyDirective,
    RegexDirective,
    PortalNavbarComponent,
    CommaSepratorPipe,
    AppDownloadAlertComponent,
    AllownumbersonlyDirective,
    RegexDirective,
  ]
})
export class CommonUtilModule { }
