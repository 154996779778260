import { Subscription } from "rxjs";

/**
 * @class StreamHelper
 * @version 1.0.0
 * 
 * @description The StreamHelper class is a helper class that 
 * allows you to subscribe to a stream and store the subscription
 * state. Reduce effort of rewriting subsciption management
 * logic. 
 * 
 * Simply extend your working class with StreamHelper, use 
 * subscribe method to subscription all your observables or 
 * streams and call unsubscibeAll method in your class destory
 * lifecycle.
 *  
 * @author Nayan Hathiwala
 */
export class StreamHelper {
    /* This is a map that stores the subscriptions. */
    private map: { [key: string]: Subscription; } = {};

    /**
     * Listen to a key and subscribe to its stream
     * @param {string} key - The key to store the subscription under.
     * @param {Subscription} stream - Subscription
     */
    subscribe(key: string, stream: Subscription): void {
        if (this.map[key]) {
            this.unsubscribe(key);
        }
        this.map[key] = stream;
    }

    /**
     * Return the subscription for the given key if it exists, otherwise return null
     * @param {string} key - The key of the subscription.
     * @returns A Subscription object.
     */
    getStream(key: string): Subscription | null {
        if (!this.map[key]) {
            return null;
        }
        return this.map[key];
    }


    /**
     * Remove the subscription from the map and unsubscribe from the observable
     * @param {string} key - string
     * @returns Nothing.
     */
    unsubscribe(key: string): void {
        if (!this.map[key]) {
            return;
        }
        this.map[key].unsubscribe();
        delete this.map[key];
    }

    /**
     * For each subscription in the map, unsubscribe from the observable and delete the subscription
     * from the map
     */
    unsubscribeAll(): void {
        for (const key of Object.keys(this.map)) {
            this.map[key].unsubscribe();
            delete this.map[key];
        }
    }

}
