<section class="rb-p-v-140 rb-p-t-sm-50 rb-p-b-sm-30">
    <div class="uk-container rb-p-sm-0">
        <div class="uk-grid uk-flex-middle uk-child-width-1-2" uk-grid>
            <div class="uk-width-1-1 uk-width-1-3@l uk-width-1-1@m uk-flex uk-flex-center uk-flex-middle">
                <div class="rb-mxw-320 rb-p-h-sm-15">
                    <h1 class="rb-h1 rb-m-0 rb-font-size-40">Our partners has to say</h1>
                    <p class="rb-font-size-16">We strive to deliver the value, where every partner will have max to earn
                        with minimum efforts.</p>
                </div>
            </div>
            <div class="uk-width-1-1 uk-width-2-3@l uk-width-1-1@m">
                <div class="uk-slider-container-offset rb-m-t-100 rb-m-t-md-0 rb-testimonial-slider"
                    uk-slider="autoplay:true; autoplay-interval:3000;">
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
                        <ul class="uk-slider-items rb-testimonial uk-grid-small uk-grid" uk-grid
                            uk-height-match="target: > div > .uk-card">
                            <div *ngFor="let t of testimonials">
                                <div class="rb-testimonial-card uk-card uk-card-default uk-box-shadow-none rb-background-blue rb-mnh-180s rb-p-25"
                                    style="width: 400px;">
                                    <div class="rb-mxw-84">
                                        <img [src]="t.photo">
                                    </div>
                                    <!-- <div class="rb-mxw-84">
                                        <span class="star-icon">
                                            <span [ngStyle]="{ 'width': getStars(t.rating) }"></span>
                                        </span>
                                    </div> -->
                                    <p class="rb-m-b-0 rb-fw-400 rb-font-size-20"><strong
                                            class="rb-fw-700">{{t.name}}</strong> has earned &nbsp;
                                        <!-- <strong class="uk-text-primary">{{amountToCurrency(t.amount)}}</strong> -->
                                        <strong class="uk-display-block uk-text-primary">{{t.amount.toString() | commaSeprator}} {{t.month}}</strong>
                                    </p>
                                    <p class="uk-text-muted rb-font-size-16 rb-m-t-0"><i>"{{t.comment}}"</i></p>
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div class="uk-grid uk-grid-small uk-grid-match rb-m-t-24">
                        <div class="uk-width-auto">
                            <a class="uk-preserve-color" href="#" uk-slider-item="previous">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="20" cy="20" r="20" fill="#F2F5F8" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M24.4049 12.5858C25.1984 13.3668 25.1984 14.6332 24.4049 15.4142L19.7465 20L24.4049 24.5858C25.1984 25.3668 25.1984 26.6332 24.4049 27.4142C23.6115 28.1953 22.3251 28.1953 21.5317 27.4142L14 20L21.5317 12.5858C22.3251 11.8047 23.6115 11.8047 24.4049 12.5858Z"
                                        fill="#282828" />
                                </svg>
                            </a>
                        </div>
                        <div class="uk-width-expand"></div>
                        <div class="uk-width-auto">
                            <a class="uk-preserve-color" href="#" uk-slider-item="next">
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle r="20" transform="matrix(-1 0 0 1 20 20)" fill="#F2F5F8" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M15.5951 12.5858C14.8016 13.3668 14.8016 14.6332 15.5951 15.4142L20.2535 20L15.5951 24.5858C14.8016 25.3668 14.8016 26.6332 15.5951 27.4142C16.3885 28.1953 17.6749 28.1953 18.4683 27.4142L26 20L18.4683 12.5858C17.6749 11.8047 16.3885 11.8047 15.5951 12.5858Z"
                                        fill="#282828" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>