import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-testimonial-slider',
  templateUrl: './testimonial-slider.component.html',
  styleUrls: ['./testimonial-slider.component.scss']
})
export class TestimonialSliderComponent implements OnInit {

    testimonials: {
        photo: string;
        name: string;
        rating: number;
        amount: number;
        month: string;
        comment: string;
    }[] = [
        {
            photo: './assets/images/t1.png',
            name: 'Swati Srivastava',
            rating: 3.5,
            amount: 166593,
            month: 'in Jan 2022',
            comment: 'RenewBuy is a great brand and provides amazing service, which is one of the best companies for Insurance POSP advisors. Nice company to get associated with!',
        },
        {
            photo: './assets/images/t2.png',
            name: 'Nandeesh B',
            rating: 4.5,
            amount: 538504,
            month: 'in Jan 2022',
            comment: 'I have been associated with RenewBuy for the last few years and it’s been a pleasure to work with such an amazing organization. My customers are highly satisfied with this company. Keep giving your best services.',
        },
            {
            photo: './assets/images/t3.png',
            name: 'Sunil Rindhe',
            rating: 4.7,
            amount: 142644,
            month: 'in Jan 2022',
            comment: 'I work according to my convenience and earn well. With this RenewBuy Partners App, I can sell many insurance products and earn a good income. Thanks, RenewBuy for your support!',
        },
        {
            photo: './assets/images/t4.png',
            name: 'Probodh Debnath',
            rating: 4,
            amount: 246113,
            month: 'in Jan 2022',
            comment: 'RenewBuy has created a way for me to earn income even after retirement. I am 65 years old and RenewBuy helps me not to be dependent on anyone. RenewBuy supported me a lot. Thanks for everything RenewBuy!',
        },
    ];

    constructor() { }

    ngOnInit(): void {
    }

    amountToCurrency(amount: number) {
        if (amount / 1000 > 1) {
        return `₹${(amount / 1000) | 0}k/month`;
        }
        return `₹${(amount / 1000) | 0}/month`;
    }

    // setting ratings star view
    getStars(rating: any): any {
        // Get the value
        const val = parseFloat(rating);
        // Turn value into number/100
        const size = val/5*100;
        return size + '%';
    }

}
