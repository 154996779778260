<section class="uk-position-relative" style="z-index: 1;">
    <div class="rb-m-0 uk-position-center-top uk-width-1-1 rb-navbar" uk-alert>
        <a class=" uk-alert-close uk-icon-button" uk-close></a>
        <div class="uk-container rb-mxw-480">
            <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-expand">
                    <h3 class="rb-h1 rb-font-size-18 rb-fw-800 rb-m-0 rb-lh-20">Join our expert POSP network!</h3>
                    <p class="rb-m-0">Download the RB Partner app.</p>
                </div>
                <div class="uk-width-auto">
                    <div class="uk-grid-small uk-flex-middle" uk-grid>
                        <div class="uk-position-relative">
                            <img src="./assets/images/union.png" height="56">
                            <img src="./assets/images/qrcodeRb.png" class="uk-position-center rb-mxw-40 rb-m-l-6"
                                height="40" width="40">
                        </div>
                        <div>
                            <a href="#"><img src="./assets/images/play.png" height="46" width="46"></a>
                        </div>
                        <div>
                            <a href="#"><img src="./assets/images/apple.png" height="46" width="46"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>