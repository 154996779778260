export interface XAuthReq {
    id?: number;
    cmd: "xauth::retrieve" | 'xauth::extend' | 'xauth::expire';
    token?: string;
    expire?: number;
    extend?: string[];
    session?: boolean;
    retrieve?: string[];
};

export interface AuthData {
    designation: string | null;
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
    pos_code: string;
    token: string;
    user_type: number | null,
    username: string,
}

export interface XAuthRes {
    id: number;
    cmd: "xauth::retrieve" | 'xauth::extend' | 'xauth::expire' | 'xauth::ready';
    tokens?: AuthData;

};
export class CrossOriginLocalStorage {
    // Check for browser capabilities
    unsupported: boolean = !(window.postMessage != undefined && window.localStorage && window.JSON);

    // Cached references
    iframe: HTMLIFrameElement | null = null;
    childWindow?: Window;
    win: Window;
    queue: XAuthReq[] = [];
    originHostname?: string;
    private _id: number = 0;

    constructor(public amsUrl: string, public allowedOrigins: string[], public onEvent: (data: XAuthRes) => any,) {
        this.win = window;
        this._setupIframe();
    }

    private _setupIframe() {
        if (this.unsupported) {
            console.error("AMS Unsupporter");
            return;
        }
        if (this.iframe) { return; }

        // Create hidden iframe dom element
        var doc = window.document;
        this.iframe = doc.createElement('iframe');
        var iframeStyle = this.iframe.style;
        iframeStyle.position = 'absolute';
        iframeStyle.left = iframeStyle.top = '-999px';

        // Append iframe to the dom and load up xauth.org inside
        doc.body.appendChild(this.iframe);
        this.iframe.src = this.amsUrl + 'pos-frame-page/';
        try {
            this.childWindow = this.iframe.contentWindow!;
        } catch (e) {
            this.childWindow = this.iframe.contentWindow!;
        }

        let originHostname: any = window.origin.split('/')[2];
        originHostname = originHostname.split('?')[0];
        originHostname = originHostname.split('.');
        originHostname = '.' + originHostname[originHostname.length - 2] + '.' + originHostname[originHostname.length - 1]
        this.originHostname = originHostname;

        this.win.addEventListener('message', (e) => { this._onmessage(e); }, false);
        if (this.queue.length > 0) {
            this._postMessage(this.queue[0])
        }
    }

    private _onmessage(event: MessageEvent) {
        // if (!this.isAllowedOrigin(event.origin)) {
        //     return;
        // }
        let data;
        try {
            data = JSON.parse(event.data);
        } catch (_) {
            data = event.data;
        }
        switch (data.cmd) {
            case 'xauth::extend':
                console.log('message data', data);
                this.retrive();
                break;
            case 'xauth::retrieve':
                console.log('message data', data);
                if (data.tokens?.items) {
                    localStorage.setItem('items', JSON.stringify(data.tokens.items));
                    localStorage.setItem('token', data.tokens.items.token);
                }
                break;
            case 'xauth::expire':
                console.log('message data', data);
                localStorage.clear();
                break;
            case 'xauth::ready':
                setTimeout(() => {
                    if (this.queue.length > 0) {
                        this.runPendingQueue();
                    } else {
                        this.retrive();
                    }
                }, 0);
                break;
            default:
                console.warn('Unknown method "' + data.cmd + '"', data);
        }
        this.onEvent(data);
    }

    private _addToQueue(data: XAuthReq) {
        if (this.unsupported) { return; }
        this._id++;
        data.id = this._id;

        // If window isn't ready, add it to a queue
        if (!this.iframe) {
            this.queue.push(data);
            // must happen after we've added to the queue
            this._setupIframe();
        } else {
            this._postMessage(data);
        }
    }

    runPendingQueue() {
        for (const q of this.queue) {
            setTimeout(() => {
                this._postMessage(q);
                this.queue.shift();
            }, 1000);
        }
    }

    private _postMessage(messageData: XAuthReq) {
        this.childWindow?.postMessage(JSON.stringify(messageData), this.amsUrl);
    }

    isAllowedOrigin(origin: string) {
        return this.allowedOrigins.includes(origin);
    }

    extend(data: AuthData) {
        const today = new Date();
        const messageData: XAuthReq = {
            cmd: 'xauth::extend',
            token: data.token,
            extend: ['items'],
            expire: today.setDate(today.getDate() + 30),
            session: true,
            items: data,
        } as any;
        this._addToQueue(messageData);
    }

    retrive() {
        const messageData: XAuthReq = {
            cmd: 'xauth::retrieve',
            retrieve: ['items'],
        };
        this._addToQueue(messageData);
    }

    expire() {
        const messageData: XAuthReq = {
            cmd: 'xauth::expire',
        };
        this._addToQueue(messageData);
    }

}
