import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-process-stepper',
  templateUrl: './process-stepper.component.html',
  styleUrls: ['./process-stepper.component.scss']
})
export class ProcessStepperComponent implements OnInit {

  processes: {
    step: number;
    title: string;
    subtitle: string;
    icon: string;
  }[] = [
      {
        step: 1,
        title: 'Sign up with your details',
        subtitle: 'Fill up the basic details to create a new account. Or Login, in case of existing account.',
        icon: './assets/images/process1.png',
      },
      {
        step: 2,
        title: 'Upload KYC documents for verification',
        subtitle: 'Complete all the KYC steps. You will need a selfie photo, Aadhar, PAN & Education Certificate',
        icon: './assets/images/process2.png',
      },
      {
        step: 3,
        title: 'Take the training & examination',
        subtitle: 'Learn how to sell your policies and quick tips to earn more money.',
        icon: './assets/images/process3.png',
      },
      {
        step: 4,
        title: 'Get IRDAI certified POSP code',
        subtitle: 'Followed by the training and examination, you will recieve an IRDA issued Certificate.',
        icon: './assets/images/process4.png',
      },
    ];

  constructor() { }

  ngOnInit(): void {
  }

}
