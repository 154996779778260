<ng-template #href let-data="data">
    <a [href]="data.href" [title]="data.title" [class]="data.class">{{data.title}}</a>
</ng-template>
<ng-template #onclick let-data="data">
    <button type="button" (click)="data.onclick" [class]="data.class" [title]="data.title">{{data.title}}</button>
</ng-template>
<ng-template #routeable let-data="data">
    <a [routerLink]="data.routerLink" [class]="data.class" [title]="data.title">{{data.title}}</a>
</ng-template>
<ng-template #dropdown let-submenu="submenu">
    <div *ngIf="submenu" class="uk-navbar-dropdown">
        <ul class="uk-nav uk-navbar-dropdown-nav">
            <ng-container *ngFor="let subitem of submenu">
                <ng-template [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{item: subitem}">
                </ng-template>
            </ng-container>
        </ul>
    </div>
</ng-template>
<ng-template #listItem let-item="item">
    <ng-container *ngIf="item.href && !item.onclick && !item.routerLink">
        <li [ngClass]="{'uk-active': !!item.isActive}">
            <ng-template [ngTemplateOutlet]="href" [ngTemplateOutletContext]="{data: item}"></ng-template>
            <ng-template [ngTemplateOutlet]="dropdown" [ngTemplateOutletContext]="{submenu: item.submenu}">
            </ng-template>
        </li>
    </ng-container>
    <ng-container *ngIf="!item.href && item.onclick && !item.routerLink">
        <li [ngClass]="{'uk-active': !!item.isActive}">
            <ng-template [ngTemplateOutlet]="onclick" [ngTemplateOutletContext]="{data: item}"></ng-template>
            <ng-template [ngTemplateOutlet]="dropdown" [ngTemplateOutletContext]="{submenu: item.submenu}">
            </ng-template>
        </li>
    </ng-container>
    <ng-container *ngIf="!item.href && !item.onclick && item.routerLink">
        <li routerLinkActive="uk-active" [routerLinkActiveOptions]="{exact: false}">
            <ng-template [ngTemplateOutlet]="routeable" [ngTemplateOutletContext]="{data: item}"></ng-template>
            <ng-template [ngTemplateOutlet]="dropdown" [ngTemplateOutletContext]="{submenu: item.submenu}">
            </ng-template>
        </li>
    </ng-container>
</ng-template>

<div class="rb-navbar" uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
    <nav class="uk-navbar-container uk-container uk-navbar-transparent" uk-navbar>
        <div class="uk-navbar-left">
            <ul class="uk-navbar-nav">
                <li>
                    <a routerLink="/"><img src="/assets/images/logo_rb.png"
                            class="d-inline-block align-center rb-navbar-logo" alt=""></a>
                </li>
            </ul>
        </div>
        <div class="uk-navbar-right">
            <ul class="uk-navbar-nav uk-visible@m">
                <ng-container *ngFor="let item of menu">
                    <ng-template [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{'item': item}"></ng-template>
                </ng-container>
            </ul>
            <a class="uk-navbar-item uk-hidden@m" href="#" uk-toggle="target: #nav_menu; cls: rb-active;"><i
                    uk-icon="menu"></i></a>
        </div>
        <div id="nav_menu" class="uk-position-absolute uk-width-1-1 uk-card uk-card-default uk-hidden@m"
            style="left: 60px; top: 74px; width: calc(100% - 60px); height: calc(100vh - 74px);">
            <div class="uk-container rb-p-h-sm-40 rb-p-h-xs-40 rb-p-v-15">
                <ul class="uk-nav-default uk-nav-parent-icon" uk-nav="multiple: true">
                    <ng-container *ngFor="let item of menu">
                        <ng-template [ngTemplateOutlet]="listItem" [ngTemplateOutletContext]="{'item': item}">
                        </ng-template>
                    </ng-container>
                </ul>
            </div>
        </div>
    </nav>
</div>