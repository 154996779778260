import { Component } from '@angular/core';
import { AmsService } from './modules/common/services/ams.service';

@Component({
  selector: 'rb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'website';
  constructor(private _ams: AmsService) {
    this._ams.setupWindow();
  }
}
