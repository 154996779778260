// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ams_api_key: '945dbc41-8587-4c62-82ca-741f914077cf',
  ams_secret_key: 'dCr8hEr8WWaSlxoNrh6Dst9PLzWQJnqD',
  dev_api_key: '7b574be9-96c2-4afc-934e-a333f9d27e94',
  dev_secret_key: 'YiBYbVTAOIT6TD2k8L3S22pou4IVBdR6',
  ams_url: 'https://accounts.rbstaging.in/',
  pos_training: 'https://dev.renewbuy.com/accounts/create_session/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
