<section class="rb-background-blue">
    <div class="uk-container rb-p-v-140 rb-p-b-sm-0 rb-p-t-sm-30">
        <div class="uk-grid uk-child-width-1-2@s uk-flex-middle">
            <div>
                <div class="rb-m-b-sm-30">
                    <div class="rb-mxw-68">
                        <img src="./assets/images/confetti.png">
                    </div>
                    <h1 class="rb-h1 rb-m-0">What you will get on joining RenewBuy?</h1>
                    <p>Take the first step to registration and start earning extra with benefits mentioned below.</p>
                    <a href="/register#signup" class="uk-button uk-button-secondary blue-clr-btn">Sign up</a>
                </div>
            </div>
            <div>
                <div class="uk-cover-container uk-background-secondary">
                    <!-- <canvas width="1920" height="1080"></canvas> -->
                    <!-- <img src="./assets/images/confetti.png" alt="" uk-cover> -->

                    <iframe width="100%" height="300" src="https://www.youtube.com/embed/cGbnYN1wBQU?rel=0"
                        title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;
                        clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>

                </div>
            </div>
        </div>
        <div class="uk-slider-container-offset rb-m-t-100 rb-m-t-sm-30" uk-slider="finite: true;">
            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
                <ul class="uk-slider-items uk-grid-small uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1 uk-grid"
                    uk-grid>
                    <li *ngFor="let stack of features">
                        <div class="uk-flex uk-flex-column">
                            <div *ngFor="let feature of stack">
                                <div
                                    class="uk-card uk-card-default uk-box-shadow-none uk-text-center rb-mnh-180 rb-p-h-25 rb-m-b-15">
                                    <div class="rb-mxw-84" style="margin: auto;">
                                        <img [src]="feature.icon">
                                    </div>
                                    <p>{{feature.title}}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
                    uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
                    uk-slider-item="next"></a>
            </div>
            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
        </div>
    </div>
</section>