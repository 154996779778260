import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqs: {
    question: string;
    answer: string;
  }[] = [
      {
        question: 'Benefits of Becoming RenewBuy’s POSP Insurance Agent?',
        answer: 'If working for 2-4 hours a day, an agent can earn upto 20-30k per month on an average.',
      },
      {
        question: 'How much one can earn as a RenewBuy POSP Insurance Agent?',
        answer: 'If working for 2-4 hours a day, an agent can earn upto 20-30k per month on an average.',
      },
      {
        question: 'Why to become a POSP Insurance Agent?',
        answer: 'If working for 2-4 hours a day, an agent can earn upto 20-30k per month on an average.',
      },
      {
        question: 'What is the Educational Qualification to become a RenewBuy POSP Insurance Agent?',
        answer: 'If working for 2-4 hours a day, an agent can earn upto 20-30k per month on an average.',
      },
      {
        question: 'Which all Insurance Companies\' policy can a RenewBuy POSP Insurance Agent sell?',
        answer: 'If working for 2-4 hours a day, an agent can earn upto 20-30k per month on an average.',
      },
      {
        question: 'Benefits of Becoming RenewBuy’s POSP Insurance Agent?',
        answer: 'If working for 2-4 hours a day, an agent can earn upto 20-30k per month on an average.',
      },
    ];

  constructor() { }

  ngOnInit(): void {
  }

}
