import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Observable, EMPTY } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StreamHelper } from '../../modules/common/classes/stream-helper';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconPack } from '@fortawesome/fontawesome-common-types';
import { faEye, faEyeSlash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../modules/common/services/auth.service';
import { IRegisterResponse } from '../../modules/common/interfaces/auth';
import { forbiddenEmailValidator, forbiddenNameValidator } from '../../modules/common/validators/validators';
import { AmsService } from '../../modules/common/services/ams.service';
declare let UIkit: any;

interface step2RegistrationResponse {
  [key: string]: string;
}


@Component({
  selector: 'rb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends StreamHelper implements OnInit {

  step1RegistrationForm!: FormGroup;
  step2RegistrationForm!: FormGroup;
  step3RegistrationForm!: FormGroup;
  currentStep: string = "Step1"
  pressed: boolean = false
  otpLength: number = 6
  step1RegistrationResponse!: IRegisterResponse;
  otpResendTimeout: number = 60
  resendDisabled: boolean = true
  submitButton: string = "Continue to Signup"
  pincodeResponse: any = []
  showPassword: boolean = false
  otpVerified: boolean = false;
  icons: IconPack = {
    eye: faEye,
    eyeslash: faEyeSlash,
    check: faCheck
  }
  onlydigitsalphabets = /[^a-zA-Z\s]*/g
  pincode: any = [];
  options: any;
  filteredOptions: Observable<any[]> = EMPTY;
  pincodeName: any = "";
  errorStep1: boolean = false
  errorStep3: boolean = false
  token?: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private renderer: Renderer2,
    private ams: AmsService,
    library: FaIconLibrary
  ) {
    super();
    library.addIcons(...Object.values(this.icons))
  }

  ngOnInit(): void {
    this.initStep1()
    this.initStep2()
    this.initStep3()
    this.pincodeGet();
    const ta_code = localStorage.getItem('ta_token');
    const reg = localStorage.getItem('registration');
    if (!!reg && reg === '0') {
      this.currentStep = 'Step3';
    }
  }

  initStep1() {
    this.step1RegistrationForm = this.fb.group({
      Step1RegistrationName: ["", [Validators.required, forbiddenNameValidator, Validators.minLength(4), Validators.maxLength(70)]],
      Step1RegistrationMobile: ["", [Validators.required, Validators.minLength(10), Validators.pattern(new RegExp(/^[6-9]\d*$/))]],
      Step1tnc: [false]
    })
  }

  initStep2() {
    this.step2RegistrationForm = this.fb.group({
      otpArray: this.fb.array([])
    })
    this.createStep2Controls()
  }

  createStep2Controls() {
    for (let i = 0; i < this.otpLength; i++) {
      (this.step2RegistrationForm.controls['otpArray'] as FormArray).push(this.fb.control(null, [Validators.required]))
    }
  }

  initStep3() {
    this.step3RegistrationForm = this.fb.group({
      Step3RegistrationEmail: ["", [Validators.required, Validators.email, forbiddenEmailValidator]],
      Step3RegistrationPassword: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/))]],
      Step3RegistrationPincode: ["", [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
      Step3RegistrationReferralCode: ["DIRECT"],
    })
  }

  startOtpTimer() {
    let timer = 60
    let optInterval = setInterval(() => {
      if (timer > 0) {
        this.otpResendTimeout = timer--
      }
      else {
        this.otpResendTimeout = 0
        this.resendDisabled = false
        clearInterval(optInterval);
      }
    }, 1000);
  }


  async submitStep1() {
    if (this.step1RegistrationForm['valid']) {
      this.errorStep1 = false
      if (this.step1RegistrationForm.get('Step1tnc')?.value) {
        let postBody = {
          "full_name": this.step1RegistrationForm.get('Step1RegistrationName')?.value?.replace(/(^\w{1})|(\s+\w{1})/g, (letter: any) => letter.toUpperCase()),
          "mobile": this.step1RegistrationForm.get('Step1RegistrationMobile')?.value
        }
        this.authService.register(postBody)
          .then(response => {
            if (response) {
              this.currentStep = "Step2";
              this.step1RegistrationResponse = response;
              this.step1RegistrationForm.get('Step1tnc')?.disable();
              this.step1RegistrationForm.get('Step1RegistrationName')?.disable();
              this.step1RegistrationForm.get('Step1RegistrationMobile')?.disable();
              this.startOtpTimer()
              if ((response as any)['registered']) {
                this.sendOtp()
              }
              else {
                // localStorage.setItem('ta_userdata', JSON.stringify(this.step1RegistrationResponse));
                this.token = this.step1RegistrationResponse.token;
                // localStorage.setItem('ta_token', this.step1RegistrationResponse.token);
                // localStorage.setItem('ta_user_type', this.step1RegistrationResponse.user_type.toString());
                // localStorage.setItem('ta_user_email', this.step1RegistrationResponse.);
                // localStorage.setItem('ta_user_name', this.step1RegistrationResponse.first_name);
                // localStorage.setItem('ta_first_name', this.step1RegistrationResponse.first_name);
                // localStorage.setItem('ta_middle_name', this.step1RegistrationResponse.middle_name);
                // localStorage.setItem('ta_last_name', this.step1RegistrationResponse.last_name);
                //// localStorage.setItem('tier', this.step1RegistrationResponse.);
                // localStorage.setItem('registration_date', this.step1RegistrationResponse.registration_date);
                // localStorage.setItem('code', this.step1RegistrationResponse.code);
                // localStorage.setItem('registration', 'false');
              }
            }
          })
      }
      else {
        UIkit.notification("Please accept terms and conditions...", { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
    }
    else {
      this.errorStep1 = true
    }
  }

  get firstName(): string {
    return localStorage.getItem('ta_first_name') ?? "";
  }

  submitStep2() {
    let otp = (this.step2RegistrationForm.get('otpArray') as FormArray).value.join("")
    if (otp && otp.length == 6) {
      this.authService.verifyOtp(this.step1RegistrationResponse['mobile'], otp)
        .then((response: step2RegistrationResponse) => {
          if (response) {
            // localStorage.setItem('token', response['token']);
            // localStorage.setItem('ta_token', response['token']);
            // localStorage.setItem('ta_userdata', JSON.stringify(this.step1RegistrationResponse));
            // localStorage.setItem('code', this.step1RegistrationResponse.code);
            this.currentStep = "Step3";
            this.otpVerified = true;
            this.submitButton = "Get Started!"
          }
        })
    }
    else {
      UIkit.notification("Please enter 6 digit OTP...", { status: 'danger', pos: 'top-right', timeout: 10000 });
    }
  }


  submitStep3() {
    if (this.step3RegistrationForm['valid']) {
      if (this.step3RegistrationForm.get('Step3RegistrationReferralCode')?.value?.trim()) {
        this.errorStep3 = false
        let postBody = {
          "email_id": this.step3RegistrationForm.get('Step3RegistrationEmail')?.value,
          "password": this.step3RegistrationForm.get('Step3RegistrationPassword')?.value,
          "ref_code": this.step3RegistrationForm.get('Step3RegistrationReferralCode')?.value ?? "DIRECT",
          "pin_code": this.step3RegistrationForm.get('Step3RegistrationPincode')?.value
        }
        this.authService.updateProfile(postBody)
          .then(response => {
            if (response) {
              // localStorage.setItem('registration', 'true');
              // localStorage.setItem('code', response.code);
              // localStorage.setItem('token', localStorage.getItem('ta_token')!);
              this.router.navigate(['/kyc']);
            }
          })
      } else {
        UIkit.notification("If you don't have any referral code. Please use DIRECT as referral code.", { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
    }
    else {
      this.errorStep3 = true
    }

  }

  submitSteps() {
    if (this.currentStep == "Step1") {
      this.submitStep1()
    } else if (this.currentStep == "Step2") {
      this.submitStep2()
    }
    else {
      this.submitStep3()
    }
  }

  sendOtp() {
    this.authService.requestOtp(this.step1RegistrationResponse['mobile'])
      .then(response => {
        if (response) {
          this.startOtpTimer();
        }
      })
  }

  showHidePassword() {
    this.showPassword = !this.showPassword
  }


  redirectLogin() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  filtering(value: any): any[] {
    const filterValue = value;
    return this.options.filter((option: any) => option.name.toString().toLowerCase().includes(filterValue));
  }

  getPincodes(pincodeId: any) {
    if (pincodeId) {
      let pincode = this.options.find((pins: any) => pins.name === pincodeId)
      if (pincode)
        this.pincodeName = pincode.name;
      else
        this.pincodeName = "";
      return pincode.name + ' - ' + pincode.city_name
    }
    return ''
  }



  pincodeGet() {
    this.subscribe('pin', this.step3RegistrationForm.get("Step3RegistrationPincode")!.valueChanges.subscribe((x: any) => {
      if (x.length > 2) {
        let postBody = {
          "model": "pincode",
          "search_term": x,
          "is_exact": false,
        }
        this.authService.getPincode(x)
          .then((response) => {
            let pinList = []
            this.options = [];
            pinList.push(response);
            pinList[0].map((res: any) => {
              this.options.push(res)
            })
            this.pinOptionsGet();
          })
      }
      else {
        this.filteredOptions = EMPTY;
      }
    })
    )
  }



  pinOptionsGet(): void {
    this.filteredOptions = this.step3RegistrationForm.get("Step3RegistrationPincode")!.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filtering(value))
      );

  }


  keytab(event: any, i: number) {
    if (this.pressed) {
      const charCode = (event.which) ? event.which : event.keyCode;
      const isCommandExecution = event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;
      if (!isCommandExecution) {
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        else {
          const current = event['srcElement']
          let input
          if (event.code !== 'Backspace') {
            if (i < this.otpLength) {
              input = event['srcElement']['parentNode']['nextSibling']['children'][0]
              input.focus();
              this.renderer.setStyle(input, 'pointer-events', 'unset');
              this.renderer.setStyle(current, 'pointer-events', 'none');
            }
          }
          if (event.code === 'Backspace') {
            if (i > 1) {
              input = event['srcElement']['parentNode']['previousSibling']['children'][0]
              input.value = '';
              input.focus();
              this.renderer.setStyle(input, 'pointer-events', 'unset');
              this.renderer.setStyle(current, 'pointer-events', 'none');
            }
          }
          this.pressed = false
          return true
        }
      }
    }
    return false;
  }

  keydownfunc(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const isCommandExecution = event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;
    if (!isCommandExecution) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      else {
        this.pressed = true
        return true
      }
    }
    return
  }

}
