<footer class="rb-b-t-1">
    <section class="uk-container rb-p-h-xs-30 rb-p-v-40">
        <nav class="uk-grid uk-flex-middle uk-grid-small" uk-grid>
            <div class="uk-width-auto">
                <a href="#"><img src="/assets/images/logo.png" class="d-inline-block align-center rb-mxh-52" alt=""></a>
            </div>
            <div class="uk-width-expand"></div>
            <div class="uk-width-auto">
                <div class="uk-grid-collapse uk-flex-middle" uk-grid>
                    <div class="uk-width-auto">
                        <p>© 2021 RenewBuy &nbsp; | &nbsp; <span class="uk-text-muted">Follow us at</span></p>
                    </div>
                    <div class="uk-width-auto">
                        <a [href]="socials.facebook" class="rb-font-size-24 rb-m-l-10" style="color: #4267B2;">
                            <fa-icon [icon]="['fab', 'facebook-square']"></fa-icon>
                        </a>
                    </div>
                    <div class="uk-width-auto">
                        <a [href]="socials.twitter" class="rb-font-size-24 rb-m-l-10" style="color: #00ACEE;">
                            <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
                        </a>
                    </div>
                    <div class="uk-width-auto">
                        <a [href]="socials.linkedIn" class="rb-font-size-24 rb-m-l-10" style="color: #0e76a8;">
                            <fa-icon [icon]="['fab', 'linkedin']"></fa-icon>
                        </a>
                    </div>
                    <div class="uk-width-auto">
                        <a [href]="socials.youtube" class="rb-font-size-24 rb-m-l-10" style="color: #c4302b;">
                            <fa-icon [icon]="['fab', 'youtube']"></fa-icon>
                        </a>
                    </div>
                    <div class="uk-width-auto">
                        <a [href]="socials.instagram" class="rb-font-size-24 rb-m-l-10" style="color: #c4302b;">
                            <fa-icon [icon]="['fab', 'instagram-square']"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
        <div class="uk-grid uk-grid-match uk-flex-top uk-child-width-1-4@m uk-child-width-1-2@s uk-child-width-1-1"
            uk-grid>
            <div class="rb-footer-grid-menu" *ngFor="let menu of menus">
                <p class="rb-footer-grid-menu-title uk-text-muted">{{menu.title}}</p>
                <ul class="rb-footer-grid-submenu">
                    <li class="rb-footer-grid-submenu-item" *ngFor="let item of menu.menuItems"><a
                            [href]="item.href">{{item.title}}</a></li>
                </ul>
            </div>
        </div>
    </section>
    <section class="rb-b-t-1">
        <div class="uk-container rb-p-h-xs-30 rb-p-v-28">
            <div class="uk-grid-match uk-child-width-1-2@m" uk-grid>
                <div class="rb-footer-grid-menu rb-footer-grid-inline-menu" *ngFor="let menu of inlineMenus">
                    <p class="rb-footer-grid-menu-title uk-text-muted">{{menu.title}}</p>
                    <ul class="rb-footer-grid-submenu">
                        <li class="rb-footer-grid-submenu-item" *ngFor="let item of menu.menuItems"><a
                                [href]="item.href">{{item.title}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</footer>