import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-app-download-alert',
  templateUrl: './app-download-alert.component.html',
  styleUrls: ['./app-download-alert.component.scss']
})
export class AppDownloadAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
