import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  /**
   * It takes an HttpRequest and returns an HttpEvent
   * @param request - The original request object.
   * @param {HttpHandler} next - The next interceptor in the chain, or the backend if no interceptors
   * in the chain.
   * @returns An observable of the response.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IkRJMDAwMjMzMjIiLCJleHAiOjE2ODQ4MjIxNzR9.eCq1LOdjiODervkQz06N2LEPZeSiEH7oXizhX4UXSFU"
    // const token = localStorage.getItem('token') || localStorage.getItem('ta_token');
    const token = localStorage.getItem('token');
    let headers: any
    if (request.url.includes("/api/v2/user/")) {
      headers = {
        'Access-Control-Allow-Origin': '*',
        'API-SECRET-KEY': environment.dev_secret_key,
        'App-ID': environment.dev_api_key,
        AUTHORIZATION: `Token ${token}`,
      }
    }
    else {
      headers = {
        'Access-Control-Allow-Origin': '*',
        'secret-key': environment.ams_secret_key,
        'api-key': environment.ams_api_key,
        AUTHORIZATION: `Token ${token}`,
      };
    }
    for (const key of request.headers.keys()) {
      if (key != 'AUTHORIZATION') {
        headers[key] = request.headers.get(key);
      } else {
        console.log(headers[key]);
        if (headers[key] == null) {
          try {
            delete headers[key];
          } catch (_) { }
        } else {
          headers[key] = request.headers.get(key);
        }
      }
    }
    if (request.url.includes('/api/v1/accounts/ams_signup/')) {
      headers = {
        'Access-Control-Allow-Origin': '*',
        'API-SECRET-KEY': 'YiBYbVTAOIT6TD2k8L3S22pou4IVBdR6',
        'app-id': '7b574be9-96c2-4afc-934e-a333f9d27e94',
      }
    }
    const reqOptions = {
      headers: new HttpHeaders({
        ...headers,
      })
    };
    // console.log('Came here', reqOptions);

    return next.handle(request.clone(reqOptions));
  }
}