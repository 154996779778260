import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { IProfile, IRegisterResponse, IRegisterStep1Response } from '../interfaces/auth';
import { AmsService } from './ams.service';
declare let UIkit: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, public ams: AmsService,) { }

  get authProfile(): IProfile | null {
    const item = localStorage.getItem('userdata');
    if (item) {
      try {
        return JSON.parse(item);
      } catch (_) { }
    }
    return null;
  }



  async register(data: {
    full_name: string;
    mobile: string;
  }): Promise<IRegisterResponse | null> {
    try {
      const _data = { device: 2, ...data };

      const res = await this.http.post<IRegisterResponse>(`/api/v1/register_v2/`, _data)
        .pipe((take(1)))
        .toPromise();
      return res ?? null;
    } catch (e) {
      let error = e as any
      error = error['error']
      // if (error['status_code'] == 400) {
      let message: string = '';
      if (typeof error['detail']['message'] === 'object') {
        message = error['detail']['message']['mobile'][0];
      } else {
        message = error['detail']['message'];
      }

      UIkit.notification(message, { status: 'danger', pos: 'top-right', timeout: 10000 });
      // }
      // throw e;
      return null;
    }
  }

  async requestOtp(mobile: string, login: boolean = false): Promise<any> {
    const body: any = {
      request_param: mobile,
      signature: 'tesllt0033913',
      browser_mode: "private",
    };
    if (login) {
      body.login = true;
    }
    try {
      const data = await this.http.post<IRegisterResponse>(`/api/v1/auth_request_v2/`, body)
        .pipe((take(1)))
        .toPromise();
      return data
    } catch (e) {
      let error = e as any
      error = error['error']
      // if (error['status_code'] == 400) {
      const detail = error['detail'];
      UIkit.notification(detail[0] ?? detail['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      // }
      throw e;
    }
  }

  async verifyOtp(auth_param: string, otp_token: string, token?: string): Promise<any> {
    const body = {
      auth_param: auth_param,
      otp_token: otp_token,
      signature: 'tesllt0033913',
      browser_mode: "private",
    };
    try {
      const data = await this.http.post<IRegisterResponse>(`/api/v1/verify_auth_v2/`, body, token ? {
        headers: {
          AUTHORIZATION: `Token ${token}`,
        },
      } : undefined)
        .pipe((take(1)))
        .toPromise();
      if (data) {
        this.ams.login({
          designation: null,
          first_name: data.first_name ?? null,
          last_name: data.last_name ?? null,
          middle_name: data.middle_name ?? null,
          pos_code: data.unique_code,
          token: data.token,
          user_type: data.user_type,
          username: data.code,
        });
        localStorage.setItem('ta_first_name', data.first_name);
        localStorage.setItem('ta_middle_name', data.middle_name);
        localStorage.setItem('ta_last_name', data.last_name);
        localStorage.setItem('ta_mobile', data.mobile);
        localStorage.setItem('registration', '0');
      }
      return data;
    } catch (e) {
      let error = e as any
      error = error['error']
      // if (error['status_code'] == 400) {
      if (error['detail']['message'])
        UIkit.notification(error['detail']['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      // }
      throw e;
    }
  }

  async updateProfile(body: {
    email_id: string;
    password: string;
    ref_code: string;
    pin_code: string;
  }): Promise<any> {
    try {
      // const user: IRegisterResponse = JSON.parse(localStorage.getItem('ta_userdata')!);
      // const user = await this.getProfile();
      await this.syncBackend({
        first_name: localStorage.getItem('ta_first_name') ?? '',
        middle_name: localStorage.getItem('ta_middle_name') ?? '',
        last_name: localStorage.getItem('ta_last_name') ?? '',
        email: body.email_id,
        password: body.password,
        mobile: localStorage.getItem('ta_mobile') ?? '',
        pin_code: parseInt(body.pin_code),
        partner_code: body?.ref_code,
        is_executive: true,
      });
      // const data = await this.http.post<any>(`/api/v1/update_partner_profile_v2/`, body)
      //   .pipe((take(1)))
      //   .toPromise();
      const data = await this.getProfile();
      if (data) {
        this.ams.login({
          designation: null,
          first_name: data.first_name ?? null,
          last_name: data.last_name ?? null,
          middle_name: null,
          pos_code: data.unique_code ?? '',
          token: localStorage.getItem('token') ?? '',
          user_type: data.user_sub_type as any ?? null,
          username: data.executive_code ?? '',
        });
        localStorage.setItem('registration', data?.email ? '1' : '0');
      }
      return data
    } catch (e) {
      let error = e as any
      error = error['error'];
      console.error(error);
      // if (error?.status_code > 400 || error?.detail?.status_code > 400) {
      if (error?.detail?.message) {
        if (typeof error['detail']['message'] === "string") {
          UIkit.notification(error['detail']['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
        } else {
          for (const msg of Object.values(error['detail']['message'])) {
            if (typeof msg === "string") {
              UIkit.notification(msg, { status: 'danger', pos: 'top-right', timeout: 10000 });
            } else {
              for (const _msg of (msg as string[])) {
                UIkit.notification(_msg, { status: 'danger', pos: 'top-right', timeout: 10000 });
              }
            }
          }
        }
      } else if (error?.message) {
        UIkit.notification(error['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      } else if (error?.detail) {
        UIkit.notification(error['detail'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
      // }
      throw e;
    }
  }

  async syncBackend(body: {
    first_name: string;
    middle_name?: string;
    last_name: string;
    email: string;
    password: string;
    mobile: string;
    pin_code: number;
    partner_code: string;
    is_executive: true;
  }): Promise<IProfile | null> {
    try {
      const data = await this.http.post<IProfile>('/api/v1/accounts/ams_signup/', body, {
        headers: {
          'API-SECRET-KEY': 'YiBYbVTAOIT6TD2k8L3S22pou4IVBdR6',
          'app-id': '7b574be9-96c2-4afc-934e-a333f9d27e94',
          AUTHORIZATION: 'Token null',
        },
      })
        .pipe((take(1)))
        .toPromise();
      console.log(data);
      return data ?? null;
    } catch (e) {
      let error = e as any
      error = error['error'];
      console.error(error);
      if (error?.status_code == 400) {
        UIkit.notification(error['detail']['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
      throw e;
    }
  }

  async getProfile(token?: string): Promise<IProfile | null> {
    try {
      const data = await this.http.get<IProfile>('/api/v1/users/doc_upload/?embed=userprofile', token ? this.setHeaders(token) : undefined)
        .pipe(take(1))
        .toPromise();
      if (data) {
        localStorage.setItem('userdata', JSON.stringify(data));
        // this.ams.login({
        //   designation: null,
        //   first_name: data.first_name ?? null,
        //   last_name: data.last_name ?? null,
        //   middle_name: null,
        //   pos_code: data.unique_code ?? '',
        //   token: localStorage.getItem('ta_token') ?? '',
        //   user_type: data.user_sub_type as any ?? null,
        //   username: data.executive_code ?? '',
        // });
      }
      return data ?? null;
    } catch (e) {
      // throw e;
      return null;
    }
  }

  async getPincode(term: string): Promise<{
    id: number;
    name: string;
    city: number;
    city_name: string;
    state_name: string;
  }[]> {
    try {
      const data = await this.http.get<any[]>(`/api/v1/search/health_pincode/`, {
        params: {
          term: term,
        }
      })
        .pipe((take(1)))
        .toPromise()
      return data ?? [];
    } catch (e) {
      let error = e as any
      error = error['error']
      if (error['status_code'] == 400) {
        UIkit.notification(error['detail']['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
      throw e;
    }
  }

  async login(body: any): Promise<any> {
    try {
      const data = await this.http.post<IRegisterResponse>(`/api/v1/login_partner_v2/`, body)
        .pipe((take(1)))
        .toPromise();
      if (data) {
        this.ams.login({
          designation: null,
          first_name: data.first_name ?? null,
          last_name: data.last_name ?? null,
          middle_name: data.middle_name ?? null,
          pos_code: data.unique_code,
          token: data.token,
          user_type: data.user_type,
          username: data.code,
        });
        localStorage.setItem('ta_first_name', data.first_name);
        localStorage.setItem('ta_middle_name', data.middle_name);
        localStorage.setItem('ta_last_name', data.last_name);
        localStorage.setItem('ta_mobile', data.mobile);
        try {
          // const profile = await this.getProfile(data.token);
          localStorage.setItem('registration', (data as any)?.email ? '1' : '0');
        } catch (_) {
          localStorage.setItem('registration', '0');
        }
      }
      return data
    } catch (e) {
      let error = e as any
      error = error['error']
      // if (error['status_code'] == 400) {
      UIkit.notification(error['detail']['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      // }
      throw e;
    }
  }

  async resetPassword(body: any, token?: string): Promise<any> {
    try {
      const data = await this.http.post<IRegisterResponse>('/api/v1/reset_partner_password/', body, token ? this.setHeaders(token) : undefined)
        .pipe((take(1)))
        .toPromise();
      return data
    } catch (e) {
      let error = e as any
      error = error['error']
      if (error['status_code'] == 400) {
        UIkit.notification(error['detail']['message'], { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
      throw e;
    }
  }

  setHeaders(headers: any) {
    let header = new HttpHeaders({
      "Authorization": `Token ${headers}`
    });
    let options = { headers: header };
    return options
  }
}

