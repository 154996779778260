<section class="rb-p-v-100 rb-p-t-sm-50 rb-p-b-sm-0">
    <div class="uk-container">
        <h1 class="rb-h1 rb-m-0 rb-font-size-40">How to become a Partner</h1>
        <p class="rb-font-size-16">We strive to deliver the value, where every partner will have max to earn with
            minimum efforts.</p>
        <div class="uk-slider-container-offset rb-m-t-40 rb-m-t-sm-50" uk-slider="finite: true;">
            <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
                <ul class="uk-slider-items rb-workpoint uk-grid-small uk-child-width-1-4@l uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1 uk-grid"
                    uk-grid uk-height-match="target: > div > .uk-card">
                    <div *ngFor="let p of processes">
                        <div
                            class="rb-workpoint-card uk-card uk-card-default uk-box-shadow-none rb-background-blue uk-text-center rb-mnh-180s rb-p-25">
                            <span class="rb-workpoint-step uk-badge rb-rb-10">{{p.step}}</span>
                            <div class="rb-mxw-84" style="margin: auto;">
                                <img [src]="p.icon">
                            </div>
                            <p class="rb-m-b-0 rb-fw-700 rb-font-size-20">{{p.title}}</p>
                            <p class="uk-text-muted rb-font-size-16 rb-m-t-0">{{p.subtitle}}</p>
                        </div>
                    </div>
                </ul>
                <a class="uk-position-center-left uk-position-small uk-hidden-hover uk-preserve-color" href="#"
                    uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small uk-hidden-hover uk-preserve-color" href="#"
                    uk-slidenav-next uk-slider-item="next"></a>
            </div>
            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
        </div>
    </div>
</section>