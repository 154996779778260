<section class="uk-background-muted rb-p-v-140 rb-p-b-sm-30 rb-p-b-xs-0 rb-p-t-sm-60">
    <div class="uk-container rb-p-xs-0">
        <div class="uk-grid uk-flex-middle uk-child-width-1-2@m" uk-grid>
            <div class="uk-flex-last@m uk-flex uk-flex-center uk-flex-middle">
                <div class="rb-mxw-430 rb-p-h-sm-15">
                    <h1 class="rb-h1 rb-m-0 rb-font-size-40">Frequently asked questions</h1>
                    <p class="rb-font-size-16">We strive to deliver the value, where every partner will have max to earn
                        with minimum efforts.</p>
                </div>
            </div>
            <div>
                <ul class="rb-b-t-1 rb-b-b-1" uk-accordion="collapsible: true">
                    <li *ngFor="let faq of faqs">
                        <a class="uk-accordion-title rb-font-size-16" href="#">{{faq.question}}</a>
                        <div class="uk-accordion-content">
                            <p>{{faq.answer}}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>