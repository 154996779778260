import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconPack } from '@fortawesome/fontawesome-common-types';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rb-journey-indicator',
  templateUrl: './journey-indicator.component.html',
  styleUrls: ['./journey-indicator.component.scss']
})
export class JourneyIndicatorComponent implements OnInit, OnChanges {
  @Input() steps: string = JSON.stringify([]);
  @Input() currentStep: number = 1;
  @Input() showStepCount: boolean = false;
  @Input() shrinkView: boolean = false;
  @Input() showhome: boolean = false;
  _steps: { title: string; subtitle?: string }[] = [];

  @Output() onStepClick = new EventEmitter<number>();

  icons: IconPack = {
    home: faHome
  }

  constructor(library: FaIconLibrary) {
    library.addIcons(...Object.values(this.icons));
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['steps']?.currentValue != changes['steps']?.previousValue) {
      this._steps = JSON.parse(this.steps);
    }
    if (changes['currentStep']?.currentValue != changes['currentStep']?.previousValue) {
      this._steps = JSON.parse(this.steps);
    }
  }

  onSelect(index: number) {
    // this.currentStep = index;
    this.onStepClick.emit(index);
  }
}
