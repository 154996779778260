import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaSeprator'
})
export class CommaSepratorPipe implements PipeTransform {

    transform(value: any) {

        if (value !== null) {
            // Using ! does not check
            if (value.indexOf(',') > -1 && value.length > 1) {
                const toNumber = value.replace(/,\s?/g, '');
                return this.thousandSeparator(toNumber);
            } else {

                return this.thousandSeparator(value);


            }
        }


    }

    thousandSeparator(val: any) {
        let result = val;
        if ((val !== null || val !== undefined)) {
            if (val > 0) {
                val = val.toString();
                let lastThree = val.substring(val.length - 3);
                const otherNumbers = val.substring(0, val.length - 3);
                if (otherNumbers !== '') {
                    lastThree = ',' + lastThree;
                    result = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
                }
            }
        }


        return result;
    }

}
