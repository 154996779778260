import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'rb-clientele-strip',
  templateUrl: './clientele-strip.component.html',
  styleUrls: ['./clientele-strip.component.scss']
})
export class ClienteleStripComponent implements OnInit {

    insurerLogoList = [
        {
            src: 'assets/insurers-logo/health/hdfc.png',
        },
        {
            src: 'assets/insurers-logo/life/max-life.jpg',
        },
        {
            src: 'assets/insurers-logo/health/manipal.png',
        },
        {
            src: 'assets/insurers-logo/health/royal-sundaram.png',
        },
        {
            src: 'assets/insurers-logo/motor/shriram.jpg',
        },
        {
            src: 'assets/insurers-logo/motor/digit.jpg',
        },
        {
            src: 'assets/insurers-logo/life/hdfc-life.jpg',
        },
        {
            src: 'assets/insurers-logo/health/aditya.png',
        },
        {
            src: 'assets/insurers-logo/life/pnb-metlife.jpg',
        },
        {
            src: 'assets/insurers-logo/motor/iffco-tokio.jpg',
        },
        {
            src: 'assets/insurers-logo/motor/future-general.jpg',
        },
        {
            src: 'assets/insurers-logo/motor/kotak.jpg',
        },
        {
            src: 'assets/insurers-logo/motor/edelweiss.jpg',
        },
        {
            src: 'assets/insurers-logo/motor/united-india.jpg',
        },
        {
            src: 'assets/insurers-logo/life/icici-prudential.jpg',
        },
        {
            src: 'assets/insurers-logo/life/india-first.jpg',
        },
        {
            src: 'assets/insurers-logo/health/icici_lombard.jpg',
        },
        {
            src: 'assets/insurers-logo/health/Maxbupa.png',
        },
        {
            src: 'assets/insurers-logo/health/Star.png',
        },
        {
            src: 'assets/insurers-logo/health/sbi_general.png',
        },
        {
            src: 'assets/insurers-logo/health/tata-aig.png',
        },
        {
            src: 'assets/insurers-logo/health/universal_sompo.png',
        },
    ]

  constructor() { }

  ngOnInit(): void {
  }

}
