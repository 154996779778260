import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  features: {
    title: string;
    icon: string;
  }[][] = [
      [
        {
          title: 'Zero investment',
          icon: './assets/images/icon-8.png'
        },
        {
          title: 'Customer retention',
          icon: './assets/images/icon-4.png'
        },
      ],
      [
        {
          title: 'Attractive timely commissions',
          icon: './assets/images/icon-7.png'
        },
        {
          title: 'New customer leads',
          icon: './assets/images/icon-3.png'
        },
      ],
      [
        {
          title: 'Contactless digital process',
          icon: './assets/images/icon-6.png'
        },
        {
          title: '360° Support for you & your customers',
          icon: './assets/images/icon-2.png'
        },
      ],
      [
        {
          title: 'Choice of 30+ leading insurers',
          icon: './assets/images/icon-5.png'
        },
        {
          title: 'Full renewal credit year on year',
          icon: './assets/images/icon-1.png'
        },
      ],
    ];

  constructor() { }

  ngOnInit(): void {
  }

}
