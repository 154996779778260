import { Injectable } from '@angular/core';
import { environment } from 'projects/website/src/environments/environment';
import { AuthData, CrossOriginLocalStorage, XAuthRes } from '../classes/cross-origin-local-storage';




@Injectable()
export class AmsService {

  // postWindow: Window | null = null;
  cross?: CrossOriginLocalStorage;
  XAuthServerUrl: string = environment.ams_url;

  // Called once on first command to create the iframe to xauth.org
  setupWindow() {
    if (this.cross) { return; }
    console.log('herere');

    const allowedOrigins = [
      'http://localhost:4300',
      'http://test.rbstaging.in',
      'https://onboarding.diff.rbstaging.in',
      'https://onboarding-signup.diff.rbstaging.in'
    ];

    // Setup postMessage event listeners
    this.cross = new CrossOriginLocalStorage(this.XAuthServerUrl, allowedOrigins, (e) => {
      this.onEvent(e);
    });
  }

  onEvent(data: XAuthRes) {
    console.log('--------------', data);
    switch (data.cmd) {
      case 'xauth::extend':
        console.log('[xauth::extend] message data', data);
        break;
      case 'xauth::retrieve':
        console.log('[xauth::retrieve] message data', data);
        break;
      case 'xauth::expire':
        console.log('[xauth::expire] message data', data);
        break;
      case 'xauth::ready':
        // this.cross?.retrive();
        break;
      default:
      // console.error('Unknown method "' + data.cmd + '"', data);
    }
  }

  get authState(): AuthData | null {
    const item = localStorage.getItem('items');
    if (item) {
      try {
        return JSON.parse(item);
      } catch (_) { }
    }
    return null;
  }

  getData() {
    if (this.cross) {
      this.cross.retrive();
    }
  }

  login(data: AuthData) {
    console.log(data, this.cross);
    if (this.cross && data) {
      this.cross.extend(data);
    }
  }

  logout() {
    if (this.cross) {
      this.cross.expire();
    }
  }
}
