import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CommonUtilModule } from './modules/common/common.module';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FramePageComponent } from './pages/frame-page/frame-page.component';
import { AuthInterceptor } from './modules/common/interceptors/auth.interceptor';
import { AmsService } from './modules/common/services/ams.service';

const MaterialModules = [
  MatAutocompleteModule,
  MatInputModule,
  MatFormFieldModule
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignInComponent,
    FramePageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonUtilModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModules,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AmsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
