import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AmsService } from '../../services/ams.service';
declare var RB_AMS_SDK: any;


@Component({
  selector: 'rb-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  @Input() menu: INavbarItem[] = [
    {
      title: 'Benefits',
      href: '/register#benefits'
    },
    {
      title: 'How to Become a Partner',
      href: '/register#become_partner'
    },
    {
      title: 'Download the App',
      href: '/register#download_app'
    },
    {
      title: 'Testimonials',
      href: '/register#testimonials'
    },
    {
      title: 'FAQs',
      href: '/register#faqs'
    },
    {
      title: 'Call:18004197151',
      href: 'tel:18004197151',
      class: 'rb-text-primary',
    },
  ];

  ams_redirection: string = 'https://accounts.rbstaging.in/';
  sso!: typeof RB_AMS_SDK;

  constructor(
    private router: Router,
    private ams: AmsService,
  ) {
    const url = this.router.url
    if (url == "/sign-in") {
      let nav_signin_arr = []
      nav_signin_arr.push(this.menu[this.menu.length - 1])
      this.menu = nav_signin_arr
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const elem = document.getElementById("AMSLogin");
    // this.sso = new RB_AMS_SDK({
    //   'userInfo': elem,
    //   'islogIn': this.setUser,
    //   'UserlogOut': this.logOut,
    //   "amsurl": this.ams_redirection,
    //   'loginBoxStyle1': true,
    //   'widgetColor': '#ef663c',
    //   'widgetFont': '14px',
    // });
    // console.log(this.sso);
    // this.ams.login();
  }

  setUser(arg: any): void {
    localStorage.setItem('token', arg.token);
    localStorage.setItem('pos_code', arg.pos_code);
    localStorage.setItem('username', arg.username);
  }

  logOut(arg: any): void {
    localStorage.clear();
    // localStorage.removeItem('ta_token');
    // localStorage.removeItem('ta_user_type');
    // localStorage.removeItem('ta_partner_id');
    // localStorage.removeItem('ta_user_email');
    // localStorage.removeItem('ta_user_name');
    // localStorage.removeItem('registration_date');
    // localStorage.removeItem('tier');
    // localStorage.removeItem('username');
    // localStorage.removeItem('pos_code');
    // localStorage.removeItem('token');
    window.location.href = this.ams_redirection + '?next=' + window.location;
  }

}

export interface INavbarItem {
  title: string;
  onclick?: () => {};
  routerLink?: string;
  href?: string;
  class?: string;
  isActive?: boolean;
  submenu?: INavbarItem[];
}
