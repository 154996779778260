import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-frame-page',
  templateUrl: './frame-page.component.html',
  styleUrls: ['./frame-page.component.scss']
})
export class FramePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const allowedOrigins = [
      'http://localhost:4300',
      'http://test.rbstaging.in:4200',
      'https://onboarding.diff.rbstaging.in',
      'https://onboarding-signup.diff.rbstaging.in'
    ];

    window.onmessage = (e) => {
      if (!allowedOrigins.includes(e.origin)) {
        return;
      }
      console.log(e);
      const payload = typeof e.data == 'string' ? JSON.parse(e.data) : e.data;
      // const payload = e.data;
      switch (payload.method) {
        case 'set':
          localStorage.setItem(payload.key, JSON.stringify(payload.data));
          break;
        case 'get':
          const parent = window.parent;
          const data = localStorage.getItem(payload.key);
          const returnPayload = {
            key: payload.key,
            method: 'storage#get',
            data: data
          }
          parent.postMessage(JSON.stringify(returnPayload), '*');
          break;
        case 'remove':
          localStorage.removeItem(payload.key);
          break;
      }
    };
  }

}
