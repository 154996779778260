import { Component, OnInit } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconPack } from '@fortawesome/fontawesome-common-types';
import { faFacebookSquare, faInstagramSquare, faLinkedin, faTwitter, faYoutube, IconDefinition } from '@fortawesome/free-brands-svg-icons';

interface IFooterMenuItem {
  title: string;
  href: string;
}

interface IFooterMenu {
  title: string;
  menuItems: IFooterMenuItem[];
}

interface ISocialLinks {
  facebook: string;
  twitter: string;
  linkedIn: string;
  youtube: string;
  instagram: string;
  [key: string]: string;
}

@Component({
  selector: 'rb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  icons: IconPack = {
    facebook: faFacebookSquare,
    twitter: faTwitter,
    linkedIn: faLinkedin,
    youtube: faYoutube,
    instagram: faInstagramSquare
  }

  socials: ISocialLinks = {
    facebook: 'https://www.facebook.com/RenewBuy/',
    twitter: 'https://twitter.com/RenewBuy',
    linkedIn: 'https://in.linkedin.com/company/renewbuyofficial?original_referer=',
    youtube: 'https://www.youtube.com/renewbuy',
    instagram: 'https://www.instagram.com/renewbuyofficial/'
  }

  menus: IFooterMenu[] = [
    {
      title: 'MOTOR INSURANCE',
      menuItems: [
        {
          title: 'Motor Insurance',
          href: 'https://www.renewbuy.com/contact-us/',
        },
        {
          title: 'Claim Assistance',
          href: 'https://www.renewbuy.com/motor-insurance/claim-assistance/',
        },
        {
          title: 'Motor Insurance Calculator',
          href: 'https://www.renewbuy.com/motor-insurance/motor-insurance-calculator/',
        },
        {
          title: 'Motor Insurance Claim',
          href: 'https://www.renewbuy.com/motor-insurance/motor-insurance-claim/',
        },
        {
          title: 'Zero Depreciation',
          href: 'https://www.renewbuy.com/motor-insurance/zero-depreciation/',
        },
        {
          title: 'Motor Insurance FAQs',
          href: 'https://www.renewbuy.com/motor-insurance/faq/',
        },
      ],
    },
    {
      title: 'CAR INSURANCE',
      menuItems: [
        {
          title: 'Car Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance/',
        },
        {
          title: 'Compare Car Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance/compare-car-insurance/',
        },
        {
          title: 'Renew Car Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance/renew-car-insurance/',
        },
        {
          title: 'Car Insurance Calculator',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance-calculator/',
        },
        {
          title: 'New Car Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance/new-car-insurance/',
        },
        {
          title: 'Car Insurance Claim',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance-claim/',
        },
        {
          title: 'Third Party Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance/third-party-insurance/',
        },
        {
          title: 'Car Insurance FAQs',
          href: 'https://www.renewbuy.com/motor-insurance/car-insurance/faq/',
        },
      ],
    },
    {
      title: 'TWO WHEELER INSURANCE',
      menuItems: [
        {
          title: 'Two wheeler insurance',
          href: 'https://www.renewbuy.com/motor-insurance/two-wheeler-insurance/',
        },
        {
          title: 'Compare Bike Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/two-wheeler-insurance/compare-two-wheeler-insurance/',
        },
        {
          title: 'Renew Two Wheeler Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/two-wheeler-insurance/renew-two-wheeler-insurance/',
        },
        {
          title: 'Two Wheeler Insurance Calculator',
          href: 'https://www.renewbuy.com/motor-insurance/two-wheeler-insurance-premium-calculator/',
        },
        {
          title: 'New Two Wheeler Insurance',
          href: 'https://www.renewbuy.com/motor-insurance/two-wheeler-insurance/new-two-wheeler-insurance/',
        },
        {
          title: 'Two Wheeler Insurance FAQs',
          href: 'https://www.renewbuy.com/motor-insurance/two-wheeler-insurance/faq/',
        },
      ],
    },
    {
      title: 'HEALTH INSURANCE',
      menuItems: [
        {
          title: 'Health Insurance',
          href: 'https://www.renewbuy.com/health-insurance/',
        },
        {
          title: 'Compare Health Insurance',
          href: 'https://www.renewbuy.com/health-insurance/compare-health-insurance/',
        },
        {
          title: 'Individual Health Insurance',
          href: 'https://www.renewbuy.com/health-insurance/individual-health-insurance-plan/',
        },
        {
          title: 'Family Health Insurance',
          href: 'https://www.renewbuy.com/health-insurance/family-health-insurance-plan/',
        },
        {
          title: 'Mediclaim Policy',
          href: 'https://www.renewbuy.com/health-insurance/mediclaim-policy/',
        },
        {
          title: 'Cashless Mediclaim Policy',
          href: 'https://www.renewbuy.com/health-insurance/mediclaim-policy/cashless-mediclaim-policy/',
        },
        {
          title: 'Top Up health Insurance',
          href: 'https://www.renewbuy.com/health-insurance/top-up-health-insurance/'
        }
      ],
    },
  ];

  inlineMenus: IFooterMenu[] = [
    {
      title: 'GENERAL',
      menuItems: [
        {
          title: 'Articles',
          href: 'https://www.renewbuy.com/articles/',
        },
        {
          title: 'Press',
          href: 'https://www.renewbuy.com/articles/',
        },
        {
          title: 'Careers',
          href: 'https://www.renewbuy.com/careers/',
        },
        {
          title: 'About us',
          href: 'https://www.renewbuy.com/about-us/',
        },
        {
          title: 'Contact us',
          href: 'https://www.renewbuy.com/contact-us/',
        },
      ],
    },
    {
      title: 'OTHERS',
      menuItems: [
        {
          title: 'Privacy Policy',
          href: 'https://www.renewbuy.com/privacy-policy/',
        },
        {
          title: ' Terms & Conditions',
          href: 'https://www.renewbuy.com/terms-and-conditions/',
        },
        {
          title: 'Disclaimer',
          href: 'https://www.renewbuy.com/disclaimer/',
        },
      ],
    },
  ];

  constructor(library: FaIconLibrary) {
    library.addIcons(...Object.values(this.icons))
  }

  ngOnInit(): void {
  }

}
