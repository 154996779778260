<section class="rb-image-picker uk-cover-container uk-placeholder rb-p-0 rb-m-80s rb-mxw-400">
    <canvas height="673" width="1000"></canvas>
    <div *ngIf="!selectedFile" class="rb-image-placeholder uk-text-center uk-position-center">
        <!-- <h3 class="uk-text-bold">Photograph</h3> -->
        <h3 class="uk-text-bold">{{title}}</h3>
        <div uk-form-custom>
            <input #imageInput (change)="previewSingleFile(imageInput)" name="document"
                accept="image/png, image/jpeg, application/pdf" type="file">
            <img class="uk-link" src="./assets/images/Vector.png">
        </div>
        <p class="uk-text-bottom uk-text-emphasis">{{subTitle}}</p>
        <p class="uk-text-meta">Supports JPEG,PNG,PDF<br>Max size 10MB</p>
    </div>

    <img *ngIf="selectedFile || (value && !photoReuploaded)" [src]="currentImage ? currentImage : value" uk-cover
        alt="">
    <ng-container *ngIf="!disableUpload">
        <!-- <div *ngIf="uploadComplete || (value && !photoReuploaded)"
            class="uk-overlay-small rb-m-t-56  rb-m-12 uk-overlay-default uk-position-top-right uk-background-secondary rb-overlay-icon uk-light uk-border-pill">
            <div><button class="uk-icon-button" uk-icon="trash" (click)="deletePhoto()"></button></div>
        </div> -->
        <div *ngIf="uploadComplete || (value && !photoReuploaded)"
            class="uk-overlay-small rb-m-t-12 rb-m-r-12 uk-overlay-default uk-position-top-right rb-overlay-icon uk-background-secondary uk-light uk-border-pill">
            <div>
                <div uk-form-custom>
                    <input #imageInput name="document" (change)="previewSingleFile(imageInput)"
                        accept="image/png, image/jpeg, image/pdf" type="file">
                    <button class="uk-icon-button uk-link" uk-icon="pencil"></button>
                </div>
            </div>
        </div>
    </ng-container>


    <div class="rb-image-preview uk-position-center">
        <div class="uk-inline">
            <div *ngIf="progressShow"
                class="uk-overlay rb-upload-complete-message uk-overlay-default uk-background-secondary uk-light uk-border-pill rb-p-20 rb-p-h-18 rb-p-t-7 rb-p-t-0 rb-p-b-15 rb-lh-32">
                <div class="rb-p-7">Uploading...</div>
                <progress class="uk-progress rb-progress-bar" [value]="vl" max="100"></progress>
            </div>
            <div *ngIf="uploadMessage"
                class="uk-overlay rb-upload-complete-message uk-overlay-default uk-background-secondary uk-light uk-border-pill rb-p-h-18 rb-p-v-0 rb-lh-42">
                <div><span class="rb-check-icon uk-margin-small-right uk-icon-button"
                        uk-icon="icon:check; ratio:0.8"></span>Uploaded</div>
            </div>
        </div>
    </div>

</section>