<rb-portal-navbar></rb-portal-navbar>
<rb-app-download-alert></rb-app-download-alert>
<section class="rb-jumbo">
    <div class="uk-containers rb-jumbo-body">
        <div class="uk-grid uk-grid-collapse uk-grid-match">
            <div class="uk-width-1-2@m uk-width-1-1 uk-cover-container signup-asset"
                style="min-height: calc(100vh - 210px - 74px);">
                <div class="uk-flex rb-jumbo-key-image uk-flex-column uk-flex-column-reverse">
                    <div class="uk-flex-bottom uk-text-center">
                        <canvas width="887" style="height: calc(100vh - 210px - 74px);"></canvas>
                        <img src="./assets/images/signin1.png" class="rb-p-h-20" alt="" uk-cover>
                    </div>
                </div>
            </div>
            <div class="uk-width-1-2@m uk-width-1-1 rb-jumbo-body">
                <div class="rb-jumbo-container uk-grid uk-flex-column uk-grid-collapse uk-child-width-1-1">
                    <div class="uk-flex-1 uk-flex-middle">
                        <div class="uk-flex rb-full-height">
                            <div class="rb-jumbo-content rb-p-v-30 rb-p-h-15">
                                <h1 class="rb-h1 rb-font-size-sm-18 rb-lh-sm-18">Earn Extra With Renewbuy</h1>
                                <div class="rb-m-t-40 rb-m-b-15">

                                    <!-- login via otp -->
                                    <form [formGroup]="step1LoginForm" *ngIf="currentStep == 'Step1'"
                                        (ngSubmit)="submitSteps()">
                                        <div class="uk-grid uk-grid-small">
                                            <div class="uk-width-2-3@m uk-width-1-1">
                                                <label for="form_name" class="uk-form-label">Register Email Id/ Mobile
                                                    Number <span class="ng-star-inserted">*</span></label>
                                                <input formControlName="Step1LoginDetails" id="form_name"
                                                    class="uk-input" type="text"
                                                    placeholder="Register Email Id/ Mobile Number"
                                                    [ngClass]="this.step1LoginForm.controls['Step1LoginDetails'].invalid  && (this.submit['loginForm1'] || this.step1LoginForm.controls['Step1LoginDetails']?.dirty || this.step1LoginForm.controls['Step1LoginDetails']?.touched)? 'uk-form-danger' : ''">
                                                <div *ngIf="this.step1LoginForm.get('Step1LoginDetails')?.hasError('email')"
                                                    class="error">
                                                    Please enter valid email.
                                                </div>
                                                <div *ngIf="this.step1LoginForm.get('Step1LoginDetails')?.hasError('startWithSpace')"
                                                    class="error">
                                                    Email should not start with space or special character
                                                </div>
                                                <div *ngIf="this.step1LoginForm.controls['Step1LoginDetails']?.invalid && (this.submit['loginForm1'] || this.step1LoginForm.controls['Step1LoginDetails']?.dirty || this.step1LoginForm.controls['Step1LoginDetails']?.touched)"
                                                    class="error">
                                                    <div
                                                        *ngIf="this.step1LoginForm.controls['Step1LoginDetails'].errors!['required']">
                                                        Required field.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="uk-grid-small uk-flex-middle rb-p-l-sm-0" uk-grid>
                                            <div class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="submit" class="uk-button uk-button-primary">Request
                                                    OTP</button></div>
                                            <div class="uk-text-center or-width" *ngIf="!forgetPasswordSection">Or</div>
                                            <div *ngIf="!forgetPasswordSection"
                                                class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="button" (click)="changeTextOtpPassword()"
                                                    class="uk-button uk-button-default password-signin uk-text-primary rb-p-0">Sign
                                                    in via Password</button>
                                            </div>
                                            <!-- <div *ngIf="forgetPasswordSection"
                                                class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="button" (click)="redirectSignin()"
                                                    class="uk-button uk-button-default password-signin uk-text-primary rb-p-0">Sign
                                                    in via OTP</button>
                                            </div> -->
                                        </div>
                                    </form>

                                    <!-- login via password -->
                                    <form [formGroup]="step2LoginForm" (ngSubmit)="submitSteps()"
                                        *ngIf="currentStep == 'Step3'">
                                        <div class="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m">
                                            <div>
                                                <label for="form_email" class="uk-form-label">Email <span
                                                        class="ng-star-inserted">*</span></label>
                                                <input formControlName="Step2LoginEmail" id="form_email"
                                                    class="uk-input" type="text" placeholder="Enter email id"
                                                    [ngClass]="this.step2LoginForm.controls['Step2LoginEmail'].invalid  && (this.submit['loginForm2'] || this.step2LoginForm.controls['Step2LoginEmail']?.dirty || this.step2LoginForm.controls['Step2LoginEmail']?.touched)? 'uk-form-danger' : ''">
                                                <div *ngIf="this.step2LoginForm.get('Step2LoginEmail')?.hasError('email')"
                                                    class="error">
                                                    Please enter valid email.
                                                </div>
                                                <div *ngIf="this.step2LoginForm.get('Step2LoginEmail')?.hasError('startWithSpace')"
                                                    class="error">
                                                    Email should not start with space or special character
                                                </div>
                                                <div *ngIf="this.step2LoginForm.controls['Step2LoginEmail']?.invalid && (this.submit['loginForm2'] || this.step2LoginForm.controls['Step2LoginEmail']?.dirty || this.step2LoginForm.controls['Step2LoginEmail']?.touched)"
                                                    class="error">
                                                    <div
                                                        *ngIf="this.step2LoginForm.controls['Step2LoginEmail'].errors!['required']">
                                                        Required field.
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label for="form_password" class="uk-form-label">Password <span
                                                        class="ng-star-inserted">*</span></label>
                                                <div class="uk-inline uk-flex">
                                                    <button type="button"
                                                        class="uk-form-icon uk-form-icon-flip eye_button"
                                                        (click)="showHidePassword()">
                                                        <fa-icon
                                                            [icon]="showPassword ? icons['eyeslash'] : icons['eye']">
                                                        </fa-icon>
                                                    </button>
                                                    <input id="form_password"
                                                        [type]="showPassword ? 'text' : 'password'"
                                                        formControlName="Step2LoginPassword" class="uk-input"
                                                        placeholder="Enter password"
                                                        [ngClass]="this.step2LoginForm.controls['Step2LoginPassword'].invalid  && (this.submit['loginForm2'] || this.step2LoginForm.controls['Step2LoginPassword']?.dirty || this.step2LoginForm.controls['Step2LoginPassword']?.touched)? 'uk-form-danger' : ''">
                                                </div>
                                                <div *ngIf="this.step2LoginForm.get('Step2LoginPassword')?.hasError('minlength') || this.step2LoginForm.get('Step2LoginPassword')?.hasError('maxlength')"
                                                    class="error">
                                                    Password should be 8 to 16 characters long
                                                </div>
                                                <div *ngIf="this.step2LoginForm.get('Step2LoginPassword')?.hasError('pattern')"
                                                    class="error">
                                                    Password should be a mix of 1 small case, 1 upper case, 1 digit, and
                                                    1 special character
                                                </div>
                                                <div *ngIf="this.step2LoginForm.controls['Step2LoginPassword']?.invalid && (this.submit['loginForm2'] || this.step2LoginForm.controls['Step2LoginPassword']?.dirty || this.step2LoginForm.controls['Step2LoginPassword']?.touched)"
                                                    class="error">
                                                    <div
                                                        *ngIf="this.step2LoginForm.controls['Step2LoginPassword'].errors!['required']">
                                                        Required field.
                                                    </div>
                                                </div>
                                                <div class="rb-m-l-10 rb-m-t-5 rb-font-size-12 forget-password"
                                                    style="text-decoration: underline;" (click)="forgetPassword()">
                                                    Forget Password</div>
                                            </div>
                                        </div>

                                        <div class="uk-grid-small uk-flex-middle rb-p-l-sm-0" uk-grid>
                                            <div class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="submit" class="uk-button uk-button-primary">Login Now</button>
                                            </div>
                                            <div class="uk-text-center or-width">Or</div>
                                            <div class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="button" (click)="changeTextOtpPassword()"
                                                    class="uk-button uk-button-default password-signin uk-text-primary rb-p-0">Sign
                                                    in via OTP</button>
                                            </div>
                                        </div>
                                    </form>

                                    <form [formGroup]="step3LoginForm" (ngSubmit)="submitSteps()"
                                        *ngIf="currentStep == 'Step4'">
                                        <div class="uk-grid uk-grid-small uk-child-width-1-1 uk-child-width-1-2@m">
                                            <div>
                                                <label for="form_password" class="uk-form-label">Password <span
                                                        class="ng-star-inserted">*</span></label>
                                                <div class="uk-inline uk-flex">
                                                    <button type="button"
                                                        class="uk-form-icon uk-form-icon-flip eye_button"
                                                        (click)="showHidePassword()">
                                                        <fa-icon
                                                            [icon]="showPassword ? icons['eyeslash'] : icons['eye']">
                                                        </fa-icon>
                                                    </button>
                                                    <input id="form_password"
                                                        [type]="showPassword ? 'text' : 'password'"
                                                        formControlName="Step3LoginPassword" class="uk-input"
                                                        placeholder="Enter password"
                                                        [ngClass]="this.step3LoginForm.controls['Step3LoginPassword'].invalid  && (this.submit['loginForm3'] || this.step3LoginForm.controls['Step3LoginPassword']?.dirty || this.step3LoginForm.controls['Step3LoginPassword']?.touched)? 'uk-form-danger' : ''">
                                                </div>
                                                <div *ngIf="this.step3LoginForm.get('Step3LoginPassword')?.hasError('minlength') || this.step3LoginForm.get('Step3LoginPassword')?.hasError('maxlength')"
                                                    class="error">
                                                    Password should be {{ forgetPasswordSection ? '8' : '6' }} to 16
                                                    characters long
                                                </div>
                                                <div *ngIf="this.step3LoginForm.get('Step3LoginPassword')?.hasError('pattern')"
                                                    class="error">
                                                    Password should be a mix of 1 small case, 1 upper case, 1 digit, and
                                                    1 special character
                                                </div>
                                                <div *ngIf="this.step3LoginForm.controls['Step3LoginPassword']?.invalid && (this.submit['loginForm2'] || this.step3LoginForm.controls['Step3LoginPassword']?.dirty || this.step3LoginForm.controls['Step3LoginPassword']?.touched)"
                                                    class="error">
                                                    <div
                                                        *ngIf="this.step3LoginForm.controls['Step3LoginPassword'].errors!['required']">
                                                        Required field.
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label for="form_password" class="uk-form-label">Confirm Password <span
                                                        class="ng-star-inserted">*</span></label>
                                                <div class="uk-inline uk-flex">
                                                    <button type="button"
                                                        class="uk-form-icon uk-form-icon-flip eye_button"
                                                        (click)="showHidePassword()">
                                                        <fa-icon
                                                            [icon]="showPassword ? icons['eyeslash'] : icons['eye']">
                                                        </fa-icon>
                                                    </button>
                                                    <input id="form_password"
                                                        [type]="showPassword ? 'text' : 'password'"
                                                        formControlName="Step3ConfirmPassword" class="uk-input"
                                                        placeholder="Enter password"
                                                        [ngClass]="this.step3LoginForm.controls['Step3ConfirmPassword'].invalid  && (this.submit['loginForm3'] || this.step3LoginForm.controls['Step3ConfirmPassword']?.dirty || this.step3LoginForm.controls['Step3ConfirmPassword']?.touched)? 'uk-form-danger' : ''">
                                                </div>
                                                <div *ngIf="this.step3LoginForm.get('Step3ConfirmPassword')?.hasError('minlength') || this.step3LoginForm.get('Step3ConfirmPassword')?.hasError('maxlength')"
                                                    class="error">
                                                    Password should be 8 to 16 characters long
                                                </div>
                                                <div *ngIf="this.step3LoginForm.get('Step3ConfirmPassword')?.hasError('pattern')"
                                                    class="error">
                                                    Password should be a mix of 1 small case, 1 upper case, 1 digit, and
                                                    1 special character
                                                </div>
                                                <div *ngIf="this.step3LoginForm.controls['Step3ConfirmPassword']?.invalid && (this.submit['loginForm2'] || this.step3LoginForm.controls['Step3ConfirmPassword']?.dirty || this.step3LoginForm.controls['Step3ConfirmPassword']?.touched)"
                                                    class="error">
                                                    <div
                                                        *ngIf="this.step3LoginForm.controls['Step3ConfirmPassword'].errors!['required']">
                                                        Required field.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="uk-grid-small uk-flex-middle rb-p-l-sm-0" uk-grid>
                                            <div class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="submit" class="uk-button uk-button-primary">Set
                                                    Password</button></div>
                                            <div class="uk-text-center or-width">Or</div>
                                            <div class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="button" (click)="changeTextOtpPassword()"
                                                    class="uk-button uk-button-default password-signin uk-text-primary rb-p-0">Sign
                                                    in via OTP</button>
                                            </div>
                                        </div>
                                    </form>

                                    <form *ngIf="currentStep == 'Step2'" [formGroup]="step1LoginForm"
                                        (ngSubmit)="submitSteps()">
                                        <div class="uk-text-emphasis">
                                            Please Enter OTP Sent on {{mobileNumber}}
                                        </div>
                                        <label class="uk-form-label">Enter OTP recieved on your phone <span
                                                class="ng-star-inserted">*</span></label>
                                        <div uk-grid class="uk-grid-collapse">
                                            <div formArrayName="Step1OtpDetails"
                                                class="uk-grid-collapse capsules uk-width-1-1 uk-child-width-1-6 uk-width-2-3@m uk-child-width-1-6@m rb-m-b-30"
                                                uk-grid>
                                                <div *ngFor="let i of [1,2,3,4,5,6]">
                                                    <input [formControlName]="i-1"
                                                        class="uk-input rb-mxw-52 rb-m-l-sm-5 rb-m-r-sm-5" maxlength="1"
                                                        type="text" (keydown)="keydownfunc($event)"
                                                        (keyup)="keytab($event,i)"
                                                        [ngStyle]="i==1 ? {'pointer-events': 'unset'} : {'pointer-events': 'none'}">
                                                </div>
                                            </div>
                                            <div class="uk-width-1-1 uk-width-1-3@m rb-font-size-12">
                                                <div class="uk-text-emphasis rb-m-l-7">
                                                    OTP sent. Resend OTP in {{otpResendTimeout}} Seconds
                                                </div>
                                                <button (click)="sendOtp()" type="button"
                                                    class="uk-form-label rb-m-b-sm-10"
                                                    [ngClass]="resendDisabled ? 'resend-btn' : 'resend-btn-active'"
                                                    [disabled]="resendDisabled">RESEND OTP</button>
                                            </div>
                                        </div>
                                        <div class="uk-grid-small uk-flex-middle rb-p-l-sm-0" uk-grid>
                                            <div class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="submit" class="uk-button uk-button-primary">Submit
                                                    OTP</button></div>
                                            <div class="uk-text-center or-width">Or</div>
                                            <div *ngIf="!forgetPasswordSection"
                                                class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="button" (click)="changeTextOtpPassword()"
                                                    class="uk-button uk-button-default password-signin uk-text-primary rb-p-0">Sign
                                                    in via Password</button>
                                            </div>
                                            <div *ngIf="forgetPasswordSection"
                                                class="uk-width-1-1 uk-child-width-1-1 uk-width-2-5@m"><button
                                                    type="button" (click)="redirectSignin()"
                                                    class="uk-button uk-button-default password-signin uk-text-primary rb-p-0">Sign
                                                    in via OTP</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div class="rb-b-b-2 rb-m-v-15"></div>
                                    <p>New Partner?</p>
                                    <button type="button" class="uk-button uk-button-default"
                                        (click)="redirectSignup()">Sign Up Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<rb-clientele-strip class="uk-display-block rb-b-t-1 rb-b-b-1"></rb-clientele-strip>
<div class="rb-p-t-15 rb-p-l-15 rb-p-r-15">
    <rb-rb-disclaimer></rb-rb-disclaimer>
</div>