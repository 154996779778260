import { Component, Input, OnInit, EventEmitter, Output, } from '@angular/core';
import { of } from 'rxjs';
declare let UIkit: any;

@Component({
  selector: 'rb-photo-uploader',
  templateUrl: './photo-uploader.component.html',
  styleUrls: ['./photo-uploader.component.scss']
})
export class PhotoUploaderComponent implements OnInit {

  @Input() title: string = "Photograph";
  @Input() subTitle: string = "Drag and drop or browse Photo";
  @Input() doc_type: string = "Photograph";
  @Input() uploadProgress: number | null = null;
  @Input() uploaded: boolean = false;
  @Input() disableUpload: boolean = false;
  @Input() maxSize: number = 10 * 1024;
  @Input() minSize?: number;
  @Input() maxWidth?: number;
  @Input() minWidth?: number;
  @Input() maxHeight?: number;
  @Input() minHeight?: number;
  @Input() value?: string | null;
  @Input() docuploaded?: boolean | null;
  @Output() onLoadClick = new EventEmitter<File | null>();

  currentImage: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;
  progressShow: boolean = false;
  uploadMessage: boolean = false;
  uploadComplete: boolean = false;
  photoReuploaded: boolean = false;
  vl: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  previewSingleFile(el: any): void {
    const files: FileList = el.files;
    if (files.length > 0) {
      this.photoReuploaded = true
      let sizeCheck;
      console.log(files);
      sizeCheck = this.validate(files[0].size);
      if (!sizeCheck) {
        this.selectedFile = el.files[0];
        const reader = new FileReader();
        this.progressShow = true;
        this.prog();
        reader.onload = () => {
          this.currentImage = reader.result;
          this.onLoadClick.emit(this.selectedFile);
        };
        reader.readAsDataURL(this.selectedFile!);
      }
      else {
        UIkit.notification(sizeCheck, { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
    }
  }

  validate(value: number): string | null {
    let max = this.maxSize * 1024
    let min = (this.minSize ?? 0) * 1024
    if (max && value > max) {
      return `${this.title || 'Value'} is more than ${this.maxSize}px.`;
    }
    if (min && value < min) {
      return `${this.title || 'Value'} is less than ${this.minSize}px.`;
    }
    return null;
  }

  prog() {
    const animate = setInterval(() => {
      this.vl += 10;
      if (this.docuploaded) {
        this.progressShow = false;
        this.uploadMessage = true;
        this.uploadComplete = true;
        this.showMessage();
        this.vl = 0;
        clearInterval(animate);
      }
      else if (this.docuploaded == false) {
        this.progressShow = false;
        this.uploadMessage = false;
        this.uploadComplete = true;
        clearInterval(animate);
      }
      this.docuploaded = null
    }, 1000);
  }

  showMessage() {
    setTimeout(() => {
      this.uploadMessage = false;
    }, 1000)
    this.uploadComplete = true
  }

  deletePhoto() {
    this.photoReuploaded = true
    this.selectedFile = null;
    this.uploadComplete = false;
    this.onLoadClick.emit(this.selectedFile);
  }

}
