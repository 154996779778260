<div uk-slider="autoplay:true; autoplay-interval:2500;">
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
        <ul class="uk-slider-items">

            <li class="rb-mxw-160 uk-display-inline-block rb-m-25" style="line-height: 56px;"
                *ngFor="let logo of insurerLogoList">
                <div class="uk-flex uk-flex-middle uk-flex-center">
                    <div>
                        <img class="rb-mxh-56" src="{{logo.src}}">
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>