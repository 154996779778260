import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StreamHelper } from '../../modules/common/classes/stream-helper';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconPack } from '@fortawesome/fontawesome-common-types';
import { faEye, faEyeSlash, faCheck } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../modules/common/services/auth.service';
import { forbiddenEmailValidator } from '../../modules/common/validators/validators';
import { AmsService } from '../../modules/common/services/ams.service';
declare let UIkit: any;

interface registerOtpStep2 {
  [key: string]: string;
}

@Component({
  selector: 'rb-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent extends StreamHelper implements OnInit, OnDestroy {

  forgetPasswordSection: boolean = false
  requestOtp: boolean = false
  mobileNumber = ""
  pressed: boolean = false
  otpLength: number = 6
  step1LoginForm!: FormGroup;
  step2LoginForm!: FormGroup;
  step3LoginForm!: FormGroup;
  otpResendTimeout: number = 60
  resendDisabled: boolean = true
  showPassword: boolean = false
  token!: string;
  registerOtpStep2Response!: registerOtpStep2
  icons: IconPack = {
    eye: faEye,
    eyeslash: faEyeSlash,
    check: faCheck
  }
  submit: any = {
    "loginForm1": false,
    "loginForm2": false,
    "loginForm3": false
  }
  currentStep: string = "Step1"

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private ams: AmsService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.subscribe('route-data', this.route.data.subscribe((data: any) => {
      this.forgetPasswordSection = !!data?.forget;
    }));
    this.subscribe('route-query', this.route.queryParams.subscribe((data: any) => {
      if (data?.login === 'password') {
        this.currentStep = 'Step3';
      }
    }));
    this.initStep1()
    this.initStep2()
    this.initStep3()
    if (localStorage.getItem('registration') === '0' || !!localStorage.getItem('items')) {
      this.redirectSignup();
    }
    // localStorage.clear();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  initStep1() {
    this.step1LoginForm = this.fb.group({
      Step1LoginDetails: ["", Validators.required],
      Step1OtpDetails: this.fb.array([])
    })
    this.createStep1Controls()
  }

  createStep1Controls() {
    for (let i = 0; i < this.otpLength; i++) {
      (this.step1LoginForm.controls['Step1OtpDetails'] as FormArray).push(this.fb.control(null))
    }
  }

  initStep2() {
    this.step2LoginForm = this.fb.group({
      Step2LoginEmail: ["", [Validators.required, Validators.email, forbiddenEmailValidator]],
      Step2LoginPassword: ["", []]
    })
  }

  initStep3() {
    this.step3LoginForm = this.fb.group({
      Step3LoginPassword: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/))]],
      Step3ConfirmPassword: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/))]]
    })
  }

  showHidePassword() {
    this.showPassword = !this.showPassword
  }

  forgetPassword() {
    this.ams.logout();
    this.router.navigate(['./forget-password'])
  }

  redirectSignin() {
    this.ams.logout();
    this.router.navigate(['/sign-in'])
  }

  redirectSignup() {
    this.ams.logout();
    this.router.navigate(['/register'])
  }

  changeTextOtpPassword() {
    if (this.currentStep == "Step1" || this.currentStep == "Step2") { //change login section to password section
      this.currentStep = "Step3"
      this.requestOtp = false
    }
    else if (this.currentStep == "Step3") { //change password section to login section
      this.currentStep = "Step1"
    }
  }

  startOtpTimer() {
    let timer = 60
    let optInterval = setInterval(() => {
      if (timer > 0) {
        this.otpResendTimeout = timer--
      }
      else {
        this.otpResendTimeout = 0
        this.resendDisabled = false
        clearInterval(optInterval);
      }
    }, 1000);
  }

  submitSteps() {
    if (this.currentStep == "Step1") { // send otp
      this.sendOtp()
    }
    else if (this.currentStep == "Step4") { //forget password section
      this.postforgetDetails()
    }
    else if (this.requestOtp) { //login via otp
      this.postOtp();
    }
    else if (this.currentStep == "Step3") { //login via password section 
      this.loginViaPassword()
    }
  }

  loginViaPassword() {
    if (this.step2LoginForm['valid']) {
      this.submit['loginForm2'] = false
      let postBody = {
        "password": this.step2LoginForm.get('Step2LoginPassword')?.value,
        "otp": "",
        "request_param": this.step2LoginForm.get('Step2LoginEmail')?.value,
        "signature": "tesllt0033913",
        "browser_mode": "private"
      }
      this.authService.login(postBody)
        .then(response => {
          if (response) {
            // localStorage.setItem('ta_token', response['token']);
            // localStorage.setItem('token', response['token']);
            // localStorage.setItem('ta_user_type', response['user_type'].toString());
            // localStorage.setItem('ta_user_name', response['first_name']);
            // localStorage.setItem('registration_date', response['registration_date']);
            // localStorage.setItem('code', response['unique_code']);
            // localStorage.setItem('registration', 'true');
            // localStorage.setItem('code', response['code']);
            // this.ams.login();
            this.router.navigate(['/kyc']);
          }
        })
    }
    else {
      this.submit['loginForm2'] = true
    }
  }

  sendOtp() {
    if (this.step1LoginForm['valid']) {
      this.submit['loginForm1'] = false;
      this.authService.requestOtp(this.step1LoginForm.get('Step1LoginDetails')?.value, true)
        .then((response: registerOtpStep2) => {
          if (response) {
            this.mobileNumber = response['mobile'].substring(0, 4) + "*****" + response['mobile'].substr(8)
            this.startOtpTimer();
            this.currentStep = "Step2"
            this.requestOtp = true
          }
        })
    }
    else {
      this.submit['loginForm1'] = true
    }
  }

  async postOtp() {
    let otp = (this.step1LoginForm.get('Step1OtpDetails') as FormArray).value.join("")
    if (otp && otp.length == 6) {
      let postBody = {
        "password": "",
        "otp": otp,
        "request_param": this.step1LoginForm.get('Step1LoginDetails')?.value,
        "signature": "tesllt0033913",
        "browser_mode": "private"
      }
      const response = await this.authService.login(postBody);
      if (response) {
        this.requestOtp = false
        // localStorage.setItem('ta_token', response['token']);
        // localStorage.setItem('ta_userdata', JSON.stringify(response));
        // localStorage.setItem('ta_user_type', response['user_type'].toString());
        // localStorage.setItem('ta_first_name', response['first_name']);
        // localStorage.setItem('ta_middle_name', response['middle_name']);
        // localStorage.setItem('ta_last_name', response['last_name']);
        // localStorage.setItem('ta_user_name', response['first_name']);

        if (this.forgetPasswordSection) {
          this.token = response['token'];
          this.currentStep = "Step4";
          localStorage.removeItem('ta_token');
        } else {

          // if (profile.email != null && profile.email != '') {
          if ((response.email != null && response.email != '') || (response.email_id != null && response.email_id != '')) {
            try {
              const profile = await this.authService.getProfile(response['token']);
              UIkit.notification("Loggedin successfully...", { status: 'success', pos: 'top-right', timeout: 10000 });
              // localStorage.setItem('userdata', JSON.stringify(profile));
              // localStorage.setItem('registration_date', response['registration_date']);
              // localStorage.setItem('registration', 'true');
              // localStorage.setItem('code', response['code']);
              // localStorage.setItem('token', response['token']);
              // this.ams.login();
              this.router.navigate(['/kyc']);
            } catch (_) {
              UIkit.notification("Somethng went wrong. Retry again!", { status: 'danger', pos: 'top-right', timeout: 10000 });
            }
          } else {
            this.router.navigate(['/register']);
          }
        }
      }
    }
    else {
      UIkit.notification("Please enter valid 6-digit otp.", { status: 'danger', pos: 'top-right', timeout: 10000 });
    }
  }

  postforgetDetails() {
    if (this.step3LoginForm['valid']) {
      this.submit['loginForm3'] = false
      let postBody = {
        "userpass": this.step3LoginForm.get('Step3LoginPassword')?.value,
        "reuserpass": this.step3LoginForm.get('Step3ConfirmPassword')?.value
      }
      if (this.step3LoginForm.get('Step3LoginPassword')?.value == this.step3LoginForm.get('Step3ConfirmPassword')?.value) {
        this.authService.resetPassword(postBody, this.token)
          .then(response => {
            if (response) {
              UIkit.notification("Password changed successfully...", { status: 'success', pos: 'top-right', timeout: 10000 });
              this.router.navigate(['/'], {
                queryParams: {
                  login: 'password'
                },
              });
            }
          })
      }
      else {
        UIkit.notification("Please verify the password.", { status: 'danger', pos: 'top-right', timeout: 10000 });
      }
    }
    else {
      this.submit['loginForm3'] = true
    }
  }

  keytab(event: any, i: number) {
    if (this.pressed) {
      const charCode = (event.which) ? event.which : event.keyCode;
      const isCommandExecution = event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;
      if (!isCommandExecution) {
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        else {
          const current = event['srcElement']
          let input
          if (event.code !== 'Backspace') {
            if (i < this.otpLength) {
              input = event['srcElement']['parentNode']['nextSibling']['children'][0]
              input.focus();
              this.renderer.setStyle(input, 'pointer-events', 'unset');
              this.renderer.setStyle(current, 'pointer-events', 'none');
            }
          }
          if (event.code === 'Backspace') {
            if (i > 1) {
              input = event['srcElement']['parentNode']['previousSibling']['children'][0]
              input.value = '';
              input.focus();
              this.renderer.setStyle(input, 'pointer-events', 'unset');
              this.renderer.setStyle(current, 'pointer-events', 'none');
            }
          }
          this.pressed = false
          return true
        }
      }
    }
    return false;
  }

  keydownfunc(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    const isCommandExecution = event.ctrlKey || event.metaKey || event.shiftKey || event.altKey;
    if (!isCommandExecution) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      else {
        this.pressed = true
        return true
      }
    }
    return
  }

}
