<section class="uk-background-muted rb-p-t-120 rb-p-t-sm-0">
    <div class="uk-container rb-p-sm-30">
        <div class="uk-grid uk-flex-middle uk-grid-collapse uk-child-width-1-2@m uk-child-width-1-1" uk-grid>
            <div class="uk-flex uk-flex-center rb-b-b-sm-1 rb-p-t-sm-30 uk-flex-bottom@m">
                <img src="./assets/images/app_mockup.png" class="rb-mxw-400 rb-mxw-sm-250">
            </div>
            <div class="uk-flex uk-flex-middle uk-child-width-1-1 ">
                <div class="rb-p-t-sm-30 uk-text-left@m uk-text-center">
                    <img src="./assets/images/qrcodeRb.png" class="rb-mxh-168">
                    <h1 class="rb-h1 rb-m-0 rb-font-size-40">Download the app now.</h1>
                    <p class="rb-font-size-16">Look for the app name “RenewBuy Partners”on Appstore & Playstore.</p>
                    <div class="uk-grid uk-grid-small uk-width-1-1 uk-flex-left@m uk-flex-center" uk-grid>
                        <div>
                            <a href="./">
                                <img src="./assets/images/android_download.png" class="rb-mxh-48">
                            </a>
                        </div>
                        <div>
                            <a href="./">
                                <img src="./assets/images/ios_download.png" class="rb-mxh-48">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>