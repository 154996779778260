import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmsService } from '../../services/ams.service';
import { AuthService } from '../../services/auth.service';
declare var RB_AMS_SDK: any;


@Component({
  selector: 'rb-portal-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class PortalNavbarComponent implements OnInit, AfterViewInit {

  @Input() menu: INavbarItem[] = [];
  @Input() isExpanded: boolean = true;
  ams_redirection: string = 'https://accounts.rbstaging.in/';
  sso!: typeof RB_AMS_SDK;


  constructor(private router: Router,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // const elem = document.getElementById("AMSLogin");
    // this.sso = new RB_AMS_SDK({
    //   'userInfo': elem,
    //   'islogIn': this.setUser,
    //   'UserlogOut': this.logOut,
    //   "amsurl": this.ams_redirection,
    //   'loginBoxStyle1': true,
    //   'widgetColor': '#ef663c',
    //   'widgetFont': '14px',
    // });
    // console.log(this.sso);
    // this.ams.login();
  }

  get name(): string {
    if (this.auth.authProfile?.first_name && this.auth.authProfile?.first_name != '') {
      return this.auth.authProfile?.first_name;
    } else if (this.auth.ams.authState?.first_name && this.auth.ams.authState?.first_name != '') {
      return this.auth.ams.authState?.first_name;
    }
    return '';
  }

  get image(): string {
    return this.auth.authProfile?.userprofile.image ?? '';
  }

  signOut() {
    this.auth.ams.logout();
    this.router.navigate(['/']);
  }

  setUser(arg: any): void {
    console.log(arg);
    localStorage.setItem('token', arg.token);
    localStorage.setItem('pos_code', arg.pos_code);
    localStorage.setItem('username', arg.username);
  }

  logOut(arg: any): void {
    // localStorage.clear();
    this.auth.ams.logout();
    // localStorage.removeItem('ta_token');
    // localStorage.removeItem('ta_user_type');
    // localStorage.removeItem('ta_partner_id');
    // localStorage.removeItem('ta_user_email');
    // localStorage.removeItem('ta_user_name');
    // localStorage.removeItem('registration_date');
    // localStorage.removeItem('tier');
    // localStorage.removeItem('username');
    // localStorage.removeItem('pos_code');
    // localStorage.removeItem('token');
    window.location.href = this.ams_redirection + '?next=' + window.location;
  }



}

export interface INavbarItem {
  title: string;
  onclick?: () => {};
  routerLink?: string;
  href?: string;
  class?: string;
  isActive?: boolean;
  submenu?: INavbarItem[];
}
