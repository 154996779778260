import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[rbRegex]'
})
export class RegexDirective {

  @Input('regexExp') regexExp: any = {};

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event:any) {
		const initalValue = this.el.nativeElement.value;
		this.el.nativeElement.value = initalValue.replace(new RegExp(this.regexExp), '');
		if (initalValue !== this.el.nativeElement.value) {
			event.stopPropagation();
		}
	}

}
