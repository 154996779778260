import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/common/guards/auth.guard';
import { NotAuthGuard } from './modules/common/guards/not-auth.guard';
import { MasterPageComponent } from './modules/common/pages/master-page/master-page.component';
import { FramePageComponent } from './pages/frame-page/frame-page.component';
import { HomeComponent } from './pages/home/home.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';

const routes: Routes = [
  {
    path: '',
    component: SignInComponent,
    canActivate: [NotAuthGuard],
    data: { forget: false }
  },
  {
    path: 'register',
    component: HomeComponent,
    canActivate: [NotAuthGuard],
  },
  {
    path: 'forget-password',
    component: SignInComponent,
    canActivate: [NotAuthGuard],
    data: { forget: true }
  },
  {
    path: 'frame-page',
    component: FramePageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'kyc',
    loadChildren: () => import('./modules/kyc/kyc.module').then(m => m.KycModule),
    component: MasterPageComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
