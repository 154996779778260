import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rb-rb-disclaimer',
  templateUrl: './rb-disclaimer.component.html',
  styleUrls: ['./rb-disclaimer.component.scss']
})
export class RbDisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
