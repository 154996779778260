import { AbstractControl } from "@angular/forms";

export function forbiddenNameValidator(control: AbstractControl): { [key: string]: any } | null {
    let fullname = control.value as string;
    if (fullname) {
        let fullname_length = fullname.length
        if (fullname[0] == " ") {
            return { startWithSpace: true };
        }
        else if (fullname.indexOf("  ") > -1) {
            return { doubleSpace: true }
        }
    }
    return null;
}

export function forbiddenPanValidator(control: AbstractControl): { [key: string]: any } | null {
    let pan = control.value as string;
    if (pan) {
        if (pan.indexOf(" ") > -1) {
            return { startWithSpace: true };
        }
        let first5 = pan.substring(0, 5)
        if (!(/^[A-Za-z]+$/.test(first5))) {
            return { alphabet: true };
        }
        let last = pan[pan.length - 1]
        if (!(/^[A-Za-z]+$/.test(last))) {
            return { alphabet: true };
        }
        let digits = pan.substring(5, 9)
        if (digits && pan.length > 5 && !(/^[0-9]+$/.test(digits))) {
            return { digits: true };
        }
    }
    return null;
}

export function forbiddenEmailValidator(control: AbstractControl): { [key: string]: any } | null {
    let email = control.value as string;
    if (email) {
        if (email[0] == " " || !(/^[A-Za-z0-9]/.test(email[0]))) {
            return { startWithSpace: true };
        }
    }
    return null;
}